<script setup>
import { onMounted, ref, computed, watch, onUnmounted } from 'vue'
import { useRouter } from 'vue-router';
import { useTranslation } from "i18next-vue";
import { useModeStore } from '../store/mode.js';
import { useThemeStore } from '../store/theme.js';
import { useCheckedItemStore } from '@/store/checkedItem.js';
import { useActivePageStore } from '../store/activePageTab.js';
import { useAddToPlaylistStore } from '../store/addToPlaylist.js';
import { useDocumentPageFilterStore } from '../store/documentPageFilter.js';
import { useNotificationTopBarStore } from '../store/notificationTopBar.js';
import { getStrokeColor, getModeStrokeColor, getContentTypeLabel } from '../utils/commonFunctions.js';
import axios from 'axios';
import i18next from 'i18next';
import useIsMobile from '../composables/isMobile.js';
import PageNavigator from '../components/PageNavigator.vue';
import DocumentsCard from '../components/DocumentsCard.vue';
import DocumentSkeletonCard from '../components/DocumentSkeletonCard.vue'; 
import CustomPagination from '../components/CustomPagination.vue';
import NotificationTopBar from '../components/NotificationTopBar.vue';
import AddAgainConfirmation from '../components/AddAgainConfirmation.vue';
import AddToPlaylistDropdown from '../components/AddToPlaylistDropdown.vue';
import CreateEditPlaylistModal from '../components/CreateEditPlaylistModal.vue';
import AddOrCreatePlaylistTopBar from '../components/AddOrCreatePlaylistTopBar.vue';

const { t } = useTranslation();
const { isMobile } = useIsMobile();
const router = useRouter();
const modeStore = useModeStore();
const themeStore = useThemeStore();
const strokeColor = getStrokeColor();
const activePageStore = useActivePageStore();
const modeStrokeColor = getModeStrokeColor();
const checkedItemStore = useCheckedItemStore();
const addToPlaylistStore = useAddToPlaylistStore();
const documentPageFilterStore = useDocumentPageFilterStore();
const notificationTopBarStore = useNotificationTopBarStore();

const subjects = ref(null);
const modules = ref(null);
const isLoading = ref(false);
const isAddRemoveWorkspaceLoading = ref(false);
const isGridDisplayPattern = ref(true);
const documentList = ref([]);
const stats = ref({
    stats: {
        total_asset_in_workspace: 0,
        total_asset_in_teacher_playlist: 0,
        total_asset: 0,
    }
});

const currentPage = ref(1);
const itemsPerPage = ref(12);
const totalItems = ref(0);

const showingFrom = computed(() => {
    return (currentPage.value - 1) * itemsPerPage.value + 1;
});

const showingTo = computed(() => {
    return totalItems.value < currentPage.value * itemsPerPage.value ? totalItems.value : currentPage.value * itemsPerPage.value;
});

const totalPages = computed(() => {
    return Math.ceil(totalItems.value / itemsPerPage.value);
});

const breadcrumbs = [
  { text: 'Laman Utama', to: { name: 'Dashboard Page' } },
  { text: t('sideMenu.Literasi') },
];

const changeDisplayPattern = () => {
    isGridDisplayPattern.value = !isGridDisplayPattern.value;
};

const toggleFilter = () => {
    documentPageFilterStore.toggleFilterDisplay();
};

const resetFilter = () => {
    documentPageFilterStore.resetFilter();
    fetchDocuments();
};

const fetchFilterData = () => {
    axios.get('api/v1/asset-broadcasts/filters', {
        params: {
            school_type: 'Literasi',
        }
    }).then(response => {
            // Update the filter options with the received data
            subjects.value = response.data.subjects;
            modules.value = response.data.modules;
        })
        .catch(error => {
            console.error('Failed to fetch filter data:', error);
        });
};

const fetchDocuments = () => {
    isLoading.value = true;
    axios.get('api/v1/asset-broadcasts', {
        params: {
            status: 1,
            school_type: 'Literasi',
            tag_id: documentPageFilterStore.filterBySubject === 'all' ? null : documentPageFilterStore.filterBySubject,
            module_id: documentPageFilterStore.filterByModule === 'all' ? null : documentPageFilterStore.filterByModule,
            per_page: itemsPerPage.value,
            order_by: 'latest',
            page: currentPage.value
        }
    }).then(response => {
        documentList.value = response.data.data.map(document => ({
            ...document,
            isHovered: false
        }));

        totalItems.value = response.data.total;
    }).catch(error => {
        console.error('Failed to fetch documents:', error);
    }).finally(() => {
        isLoading.value = false;
    });
}

const showBarAndUpdateSelectedFile = (id) => {
    const checkbox = document.getElementById('checkbox-' + id);

    if (checkbox && checkbox.checked) {
        checkedItemStore.addCheckedItem(id);
        addToPlaylistStore.setShowAddOrCreatePlaylistTopBar(true);
        addToPlaylistStore.setSelectedAssetBroadcastIds([...addToPlaylistStore.selectedAssetBroadcastIds, id]);
    } else { //unchecked
        checkedItemStore.removeCheckedItem(id);
        addToPlaylistStore.setSelectedAssetBroadcastIds(addToPlaylistStore.selectedAssetBroadcastIds.filter(fileId => fileId !== id));
    }

    if (addToPlaylistStore.selectedAssetBroadcastIds.length === 0) {
        addToPlaylistStore.setShowAddOrCreatePlaylistTopBar(false);
    }
}

const goToDocumentPageWithSubjectFilter = (id) => {
    documentPageFilterStore.setIsFilterDisplay(true);
    documentPageFilterStore.setFilterBySubject(id);
    router.push('/literacy');
}

const goToDocument = (id) => {
    router.push({ name: 'DocumentDetailed', params: { id: id } });
}

const cancelAddToPlaylist = () => {
    addToPlaylistStore.setSelectedPlaylistIds([]);
    addToPlaylistStore.setShowAddToPlaylistDropdown(false);

    const radios = document.querySelectorAll("input[name='playlist-radio']");
    radios.forEach(radio => {
        radio.checked = false;
    });
}

const addAnyWayToPlaylist = () => {
    if (addToPlaylistStore.selectedPlaylistIds && addToPlaylistStore.selectedPlaylistIds.length > 0) {
        let playlistId = addToPlaylistStore.selectedPlaylistIds[0];
        axios.post(`api/v1/user-playlists/${playlistId}/asset-broadcasts`, {
            assets: addToPlaylistStore.selectedAssetBroadcastIds.map((id, index) => ({
                asset_id: id,
                order: (index + 1)
            }))
        })
        .then((response) => {
            addToPlaylistStore.isShowAddAgainConfirmation = false;
            notificationTopBarStore.playlistIdUsedInAction = addToPlaylistStore.selectedPlaylistIds[0];
            cancelAddToPlaylist();
            addToPlaylistStore.setShowAddOrCreatePlaylistTopBar(false);
            notificationTopBarStore.notificationType = 'addAssetsToPlaylist';
            notificationTopBarStore.notificationMessage = `Ditambah Semula ke '<strong>${response.data.user_playlist_name}</strong>'`;
            notificationTopBarStore.idsUsedInAction = addToPlaylistStore.selectedAssetBroadcastIds;
            notificationTopBarStore.setIsShowNotificationTopBar(true);
            checkedItemStore.clearCheckedItems();
        })
        .catch((error) => {
            console.error('Error in the request', error);
        })
        .finally(() => {
            addToPlaylistStore.setIsAddingToPlaylistLoading(false);
        });
    } else {
        addToPlaylistStore.isShowAddAgainConfirmation = false;
        cancelAddToPlaylist();
        addToPlaylistStore.setShowAddOrCreatePlaylistTopBar(false);
    }
}

const addToWorkspace = async (id) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        const response = await axios.post('api/v1/workspaces/asset_broadcasts', {
            asset_broadcast_id: id
        });

        if (response.status === 200 || response.status === 201) { 

            // Set up notification after successful addition
            notificationTopBarStore.setIdUsedInAction(id);
            notificationTopBarStore.setNotificationMessage(i18next.t('notificationTopBar.addedToWorkspace'));
            notificationTopBarStore.setNotificationType('addAssetBroadcastToWorkspace');
            notificationTopBarStore.setIsShowNotificationTopBar(true);

        } else {
            console.error('Failed to add asset broadcast to workspace:', response);
        }

        getStats();
        fetchDocuments();
    } catch (error) {
        console.error('Error adding asset broadcast to workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}

const removeFromWorkspace = async (id) => {
    try {
        isAddRemoveWorkspaceLoading.value = true;
        await axios.delete(`api/v1/workspaces/asset_broadcasts/${id}`);
        notificationTopBarStore.setIdUsedInAction(id);
        notificationTopBarStore.setNotificationMessage(i18next.t('notificationTopBar.removedFromWorkspace'));
        notificationTopBarStore.setNotificationType('removeAssetBroadcastFromWorkspace');
        notificationTopBarStore.setIsShowNotificationTopBar(true);
        getStats();
        fetchDocuments();
    } catch (error) {
        console.error('Error removing playlist from workspace:', error);
    } finally {
        isAddRemoveWorkspaceLoading.value = false;
    }
}

const getStats = () => {
    axios.get('api/v1/asset-broadcasts/stats', {
        params: {
            school_type: 'Literasi',
        }
    })
    .then((response) => {
       stats.value = response.data;
    })
    .catch((error) => {
        console.log(error);
    });
};

const changePage = (page) => {
    currentPage.value = page;
    fetchDocuments(); 
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

watch(
  () => [
    documentPageFilterStore.filterBySubject,
    documentPageFilterStore.filterByModule,
  ],
  () => {
    currentPage.value = 1;
    fetchDocuments();
  }
);

onMounted(() => {
    activePageStore.setSelectedActivePage(6, 'Literasi', 'Literacy Page');
    getStats();
    fetchFilterData();
    fetchDocuments();
});

onUnmounted(() => {
    documentPageFilterStore.resetFilter();
    checkedItemStore.clearCheckedItems();
});

</script>

<template>
    <div class="min-h-screen overflow-x-scroll hide-scrollbar">
        <NotificationTopBar v-show="notificationTopBarStore.isShowNotificationTopBar" />
        <page-navigator :breadcrumbs="breadcrumbs" />
        <AddOrCreatePlaylistTopBar v-show="addToPlaylistStore.showAddOrCreatePlaylistTopBar" />
        <AddToPlaylistDropdown v-show="addToPlaylistStore.showAddToPlaylistDropdown" />
        <CreateEditPlaylistModal v-show="addToPlaylistStore.showCreateOrEditPlaylistModal" :isAtPlaylistPages="false"/>
        <AddAgainConfirmation v-if="addToPlaylistStore.isShowAddAgainConfirmation" @addanyway-to-playlist="addAnyWayToPlaylist" :title="addToPlaylistStore.selectedPlaylistName"/>
        <div :class="{'text-black': modeStore.selectedMode === 'light', ' text-white': modeStore.selectedMode === 'dark'}" class="flex justify-between justify-items-center my-auto py-4 px-[30px]">
            <h2 :class="{'mobile-title': isMobile, 'page-top-title': !isMobile}">{{ $t('sideMenu.Literasi')}}</h2>
        </div>

        <div>
            <!-- metadata-->
            <div :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}" class="grid grid-cols-3 gap-2 md:gap-4 px-[30px]">
                <div :class="[{'bg-white bg-opacity-30': modeStore.selectedMode === 'light', 'dark-mode-metadata-bar-orange-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'orange'), 'dark-mode-metadata-bar-green-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'green'), 'dark-mode-metadata-bar-blue-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'blue'), 'dark-mode-metadata-bar-purple-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'purple'), 'dark-mode-metadata-bar-darkpink-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'darkpink')}]" class="p-4 rounded-3xl">
                    <div class="flex flex-col md:flex-row md:gap-4">
                        <div class="metadata-clock my-auto">
                            <svg v-if="modeStore.selectedMode === 'dark'" class="max-w-[30px] md:max-w-[50px] lg:max-w-none" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="50" height="50" rx="25" fill="#FFF7ED" fill-opacity="0.2"/>
                            <path d="M27.3625 13.6012L21.5 12.6762C16.6125 11.9137 14.35 13.5637 13.575 18.4512L12.65 24.3137C12.15 27.5137 12.675 29.5887 14.4875 30.8512C15.4375 31.5262 16.7375 31.9762 18.425 32.2387L24.2875 33.1637C29.175 33.9262 31.4375 32.2762 32.2125 27.3887L33.125 21.5262C33.275 20.5637 33.3375 19.7012 33.2875 18.9387C33.125 15.8137 31.2875 14.2137 27.3625 13.6012ZM20.3 21.6887C18.8375 21.6887 17.65 20.5012 17.65 19.0512C17.65 17.5887 18.8375 16.4012 20.3 16.4012C21.75 16.4012 22.9375 17.5887 22.9375 19.0512C22.9375 20.5012 21.75 21.6887 20.3 21.6887Z" fill="white"/>
                            <path d="M35.6258 26.8367L33.7508 32.4742C32.1883 37.1742 29.6883 38.4242 24.9883 36.8617L19.3508 34.9867C17.5883 34.3992 16.3133 33.6742 15.4883 32.7617C16.2758 33.0742 17.1883 33.3117 18.2258 33.4742L24.1008 34.3992C24.9008 34.5242 25.6508 34.5867 26.3508 34.5867C30.4758 34.5867 32.6883 32.3617 33.4508 27.5742L34.3633 21.7117C34.4883 20.9867 34.5383 20.3492 34.5383 19.7617C36.4383 21.3242 36.7133 23.5492 35.6258 26.8367Z" fill="white"/>
                            </svg>
                            <svg v-else class="max-w-[30px] md:max-w-none" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="50" height="50" rx="25" :fill=strokeColor fill-opacity="0.2"/>
                            <path d="M27.3625 13.6012L21.5 12.6762C16.6125 11.9137 14.35 13.5637 13.575 18.4512L12.65 24.3137C12.15 27.5137 12.675 29.5887 14.4875 30.8512C15.4375 31.5262 16.7375 31.9762 18.425 32.2387L24.2875 33.1637C29.175 33.9262 31.4375 32.2762 32.2125 27.3887L33.125 21.5262C33.275 20.5637 33.3375 19.7012 33.2875 18.9387C33.125 15.8137 31.2875 14.2137 27.3625 13.6012ZM20.3 21.6887C18.8375 21.6887 17.65 20.5012 17.65 19.0512C17.65 17.5887 18.8375 16.4012 20.3 16.4012C21.75 16.4012 22.9375 17.5887 22.9375 19.0512C22.9375 20.5012 21.75 21.6887 20.3 21.6887Z" :fill=strokeColor />
                            <path d="M35.6258 26.8367L33.7508 32.4742C32.1883 37.1742 29.6883 38.4242 24.9883 36.8617L19.3508 34.9867C17.5883 34.3992 16.3133 33.6742 15.4883 32.7617C16.2758 33.0742 17.1883 33.3117 18.2258 33.4742L24.1008 34.3992C24.9008 34.5242 25.6508 34.5867 26.3508 34.5867C30.4758 34.5867 32.6883 32.3617 33.4508 27.5742L34.3633 21.7117C34.4883 20.9867 34.5383 20.3492 34.5383 19.7617C36.4383 21.3242 36.7133 23.5492 35.6258 26.8367Z" :fill=strokeColor />
                            </svg>
                        </div>
                        <div>
                            <div v-if="stats.stats.total_asset_in_workspace > 1 "><span class="font-semibold text-sm md:text-lg">{{ stats.stats.total_asset_in_workspace }}</span> {{ $t('documentMetaDataBar.assets')}}</div>
                            <div v-else><span class="font-semibold text-sm md:text-lg">{{ stats.stats.total_asset_in_workspace }}</span> {{ $t('documentMetaDataBar.asset')}}</div>
                            <div class="font-semibold text-sm md:text-lg">{{ $t('documentMetaDataBar.inWorkspace')}}</div>
                        </div>
                    </div>
                </div>
                <div :class="[{'bg-white bg-opacity-30': modeStore.selectedMode === 'light', 'dark-mode-metadata-bar-orange-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'orange'), 'dark-mode-metadata-bar-green-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'green'), 'dark-mode-metadata-bar-blue-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'blue'), 'dark-mode-metadata-bar-purple-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'purple'), 'dark-mode-metadata-bar-darkpink-bg-1': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'darkpink')}]" class="p-4 rounded-3xl">
                    <div class="flex flex-col md:flex-row md:gap-4">
                        <div class="metadata-playlist my-auto">
                            <svg v-if="modeStore.selectedMode === 'dark'" class="max-w-[30px] md:max-w-[50px] lg:max-w-none" width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.667969" y="0.5" width="50" height="50" rx="25" fill="#FFF7ED" fill-opacity="0.2"/>
                            <path d="M25.668 13.0029C18.768 13.0029 13.168 18.6029 13.168 25.5029C13.168 32.4029 18.768 38.0029 25.668 38.0029C32.568 38.0029 38.168 32.4029 38.168 25.5029C38.168 18.6029 32.568 13.0029 25.668 13.0029ZM28.993 27.6654L27.393 28.5904L25.793 29.5154C23.7305 30.7029 22.043 29.7279 22.043 27.3529V25.5029V23.6529C22.043 21.2654 23.7305 20.3029 25.793 21.4904L27.393 22.4154L28.993 23.3404C31.0555 24.5279 31.0555 26.4779 28.993 27.6654Z" fill="white"/>
                            </svg>
                            <svg v-else class="max-w-[30px] md:max-w-[50px] lg:max-w-none" width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.667969" y="0.5" width="50" height="50" rx="25" :fill=strokeColor fill-opacity="0.2"/>
                            <path d="M25.668 13.0029C18.768 13.0029 13.168 18.6029 13.168 25.5029C13.168 32.4029 18.768 38.0029 25.668 38.0029C32.568 38.0029 38.168 32.4029 38.168 25.5029C38.168 18.6029 32.568 13.0029 25.668 13.0029ZM28.993 27.6654L27.393 28.5904L25.793 29.5154C23.7305 30.7029 22.043 29.7279 22.043 27.3529V25.5029V23.6529C22.043 21.2654 23.7305 20.3029 25.793 21.4904L27.393 22.4154L28.993 23.3404C31.0555 24.5279 31.0555 26.4779 28.993 27.6654Z" :fill=strokeColor />
                            </svg>
                        </div>
                        <div>
                            <div><span class="font-semibold text-sm md:text-lg">{{ stats.stats.total_asset_in_teacher_playlist }}</span> {{ $t('documentMetaDataBar.collection')}}</div>
                            <div class="font-semibold text-sm md:text-lg">{{ $t('documentMetaDataBar.fromTeacher')}}</div>
                        </div>
                    </div>
                </div>
                <div :class="[{'bg-white bg-opacity-30': modeStore.selectedMode === 'light', 'dark-mode-metadata-bar-orange-bg-3': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'orange'), 'dark-mode-metadata-bar-green-bg-3': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'green'), 'dark-mode-metadata-bar-blue-bg-3': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'blue'), 'dark-mode-metadata-bar-purple-bg-3': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'purple'), 'dark-mode-metadata-bar-darkpink-bg-3': (modeStore.selectedMode === 'dark' && themeStore.selectedTheme === 'darkpink')}]" class="p-4 rounded-3xl">
                    <div class="flex flex-col md:flex-row md:gap-4">
                        <div class="metadata-playlist my-auto">
                            <svg v-if="modeStore.selectedMode === 'dark'" class="max-w-[30px] md:max-w-[50px] lg:max-w-none" xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 51 50" fill="none">
                            <rect x="0.333496" width="50" height="50" rx="25" fill="#FFF7ED" fill-opacity="0.2"/>
                            <path d="M30.0833 12.7607C29.5708 12.2482 28.6833 12.5982 28.6833 13.3107V17.6732C28.6833 19.4982 30.2333 21.0107 32.1208 21.0107C33.3083 21.0232 34.9583 21.0232 36.3708 21.0232C37.0833 21.0232 37.4583 20.1857 36.9583 19.6857C35.1583 17.8732 31.9333 14.6107 30.0833 12.7607Z" fill="white"/>
                            <path d="M35.9585 22.736H32.346C29.3835 22.736 26.971 20.3235 26.971 17.361V13.7485C26.971 13.061 26.4085 12.4985 25.721 12.4985H20.421C16.571 12.4985 13.4585 14.9985 13.4585 19.461V30.536C13.4585 34.9985 16.571 37.4985 20.421 37.4985H30.246C34.096 37.4985 37.2085 34.9985 37.2085 30.536V23.986C37.2085 23.2985 36.646 22.736 35.9585 22.736ZM24.7085 32.186H19.7085C19.196 32.186 18.771 31.761 18.771 31.2485C18.771 30.736 19.196 30.311 19.7085 30.311H24.7085C25.221 30.311 25.646 30.736 25.646 31.2485C25.646 31.761 25.221 32.186 24.7085 32.186ZM27.2085 27.186H19.7085C19.196 27.186 18.771 26.761 18.771 26.2485C18.771 25.736 19.196 25.311 19.7085 25.311H27.2085C27.721 25.311 28.146 25.736 28.146 26.2485C28.146 26.761 27.721 27.186 27.2085 27.186Z" fill="white"/>
                            </svg>

                            <svg v-else class="max-w-[30px] md:max-w-[50px] lg:max-w-none" xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 51 50" fill="none">
                            <rect x="0.333496" width="50" height="50" rx="25" fill="#FFF7ED" fill-opacity="0.2"/>
                            <path d="M30.0833 12.7607C29.5708 12.2482 28.6833 12.5982 28.6833 13.3107V17.6732C28.6833 19.4982 30.2333 21.0107 32.1208 21.0107C33.3083 21.0232 34.9583 21.0232 36.3708 21.0232C37.0833 21.0232 37.4583 20.1857 36.9583 19.6857C35.1583 17.8732 31.9333 14.6107 30.0833 12.7607Z" :fill=strokeColor />
                            <path d="M35.9585 22.736H32.346C29.3835 22.736 26.971 20.3235 26.971 17.361V13.7485C26.971 13.061 26.4085 12.4985 25.721 12.4985H20.421C16.571 12.4985 13.4585 14.9985 13.4585 19.461V30.536C13.4585 34.9985 16.571 37.4985 20.421 37.4985H30.246C34.096 37.4985 37.2085 34.9985 37.2085 30.536V23.986C37.2085 23.2985 36.646 22.736 35.9585 22.736ZM24.7085 32.186H19.7085C19.196 32.186 18.771 31.761 18.771 31.2485C18.771 30.736 19.196 30.311 19.7085 30.311H24.7085C25.221 30.311 25.646 30.736 25.646 31.2485C25.646 31.761 25.221 32.186 24.7085 32.186ZM27.2085 27.186H19.7085C19.196 27.186 18.771 26.761 18.771 26.2485C18.771 25.736 19.196 25.311 19.7085 25.311H27.2085C27.721 25.311 28.146 25.736 28.146 26.2485C28.146 26.761 27.721 27.186 27.2085 27.186Z" :fill=strokeColor />
                            </svg>
                        </div>
                        <div>
                            <div><span class="font-semibold text-sm md:text-lg">{{ stats.stats.total_asset }}</span> {{ $t('documentMetaDataBar.bahan')}}</div>
                            <div class="font-semibold text-sm md:text-lg">{{ $t('title.Literasi')}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <section
                class="rounded-xl p-[30px] bg-transparent"
                :class="{'bg-transparent text-black': modeStore.selectedMode === 'light', 'bg-custom-blue-gray text-white': modeStore.selectedMode === 'dark'}"
            >
                <div class="flex justify-between justify-items-center my-auto">
                    <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('title.file')}}</h2>
                    <div class="my-auto h-10">
                        <div class="flex gap-1 justify-between h-10 my-auto">
                            <div @click="changeDisplayPattern()" class=" w-8 my-auto cursor-pointer">
                                <svg v-if="isGridDisplayPattern" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.75 8.75H26.25" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round"/>
                                <path d="M3.75 15H26.25" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round"/>
                                <path d="M3.75 21.25H26.25" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round"/>
                                </svg>

                                <svg v-else width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M27.5 10.65V4.975C27.5 3.2125 26.7 2.5 24.7125 2.5H19.6625C17.675 2.5 16.875 3.2125 16.875 4.975V10.6375C16.875 12.4125 17.675 13.1125 19.6625 13.1125H24.7125C26.7 13.125 27.5 12.4125 27.5 10.65Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M27.5 24.7125V19.6625C27.5 17.675 26.7 16.875 24.7125 16.875H19.6625C17.675 16.875 16.875 17.675 16.875 19.6625V24.7125C16.875 26.7 17.675 27.5 19.6625 27.5H24.7125C26.7 27.5 27.5 26.7 27.5 24.7125Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13.125 10.65V4.975C13.125 3.2125 12.325 2.5 10.3375 2.5H5.2875C3.3 2.5 2.5 3.2125 2.5 4.975V10.6375C2.5 12.4125 3.3 13.1125 5.2875 13.1125H10.3375C12.325 13.125 13.125 12.4125 13.125 10.65Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13.125 24.7125V19.6625C13.125 17.675 12.325 16.875 10.3375 16.875H5.2875C3.3 16.875 2.5 17.675 2.5 19.6625V24.7125C2.5 26.7 3.3 27.5 5.2875 27.5H10.3375C12.325 27.5 13.125 26.7 13.125 24.7125Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>

                            <div @click="toggleFilter()" class="w-8 my-auto cursor-pointer">
                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.75 2.62402H23.25C24.625 2.62402 25.75 3.74902 25.75 5.12402V7.87402C25.75 8.87402 25.125 10.124 24.5 10.749L19.125 15.499C18.375 16.124 17.875 17.374 17.875 18.374V23.749C17.875 24.499 17.375 25.499 16.75 25.874L15 26.999C13.375 27.999 11.125 26.874 11.125 24.874V18.249C11.125 17.374 10.625 16.249 10.125 15.624L5.375 10.624C4.75 9.99902 4.25 8.87402 4.25 8.12402V5.24902C4.25 3.74902 5.375 2.62402 6.75 2.62402Z" :stroke="modeStrokeColor" stroke-width="2.25" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Filters-->
                <div v-if="documentPageFilterStore.isFilterDisplay">
                    <div class="flex justify-between">
                        <h3 :class="modeStore.selectedMode === 'dark'? 'text-white' : 'text-black'" class="text-xl">{{ $t('documentPage.filter')}}</h3>
                        <div @click="resetFilter()" class="cursor-pointer">
                            <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.9173 13.0007C22.9173 18.7507 18.2507 23.4173 12.5007 23.4173C6.75065 23.4173 3.24023 17.6257 3.24023 17.6257M3.24023 17.6257H7.94857M3.24023 17.6257V22.834M2.08398 13.0007C2.08398 7.25065 6.70898 2.58398 12.5007 2.58398C19.4486 2.58398 22.9173 8.37565 22.9173 8.37565M22.9173 8.37565V3.16732M22.9173 8.37565H18.2923" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>

                    <div class="grid md:grid-cols-2 gap-10 py-4">
                        <div :class="modeStore.selectedMode === 'dark'? 'dark-mode' : 'light-mode'">
                            <select v-model="documentPageFilterStore.filterBySubject" class="w-full py-2 px-4 my-2 rounded-md border-[1px] border-gray-400 text-gray-400 hover:border-gray-300 hover:text-gray-300 focus:text-white focus:border-white cursor-pointer">
                                <option value="all" class="text-black">{{ $t('documentPage.subject')}}</option>
                                <option v-for="subject in subjects" :value="subject.id" :key="subject.id" class="text-black">{{ subject.name }}</option>
                            </select>
                        </div>
                        <div :class="modeStore.selectedMode === 'dark'? 'dark-mode' : 'light-mode'">
                            <select v-model="documentPageFilterStore.filterByModule" class="w-full py-2 px-4 my-2 rounded-md border-[1px] border-gray-400 text-gray-400 hover:border-gray-300 hover:text-gray-300 focus:text-white focus:border-white cursor-pointer">
                                <option value="all" class="text-black">{{ $t('documentPage.moduleType')}}</option>
                                <option v-for="module in modules" :value="module.id" :key="module.id" class="text-black">{{ module.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div v-if="isGridDisplayPattern && isLoading" class="grid grid-cols-2 md:grid-cols-4 gap-4">
                    <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                    <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                    <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                    <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                </div>

                <div v-else-if="!isGridDisplayPattern && isLoading" class="flex items-center justify-center w-full gap-2">
                    <div>{{ $t('general.loading')}}</div>
                    <span class="loading loading-bars loading-sm"></span>
                </div>

                <div v-if="!isLoading && totalItems > 0">
                    <!--grid layout view -->
                    <div v-if="isGridDisplayPattern && !isLoading" class="grid grid-cols-2 md:grid-cols-4 gap-4">
                        <DocumentsCard
                                class="md:min-w-0 lg:w-full"
                                v-for="asset in documentList"
                                :key="asset.id"
                                :id="asset.id"
                                :image="asset.thumbnails[0]?.url"
                                :topic="asset.title"
                                :groups="asset.groups ? asset.groups : []"
                                :subjects="asset.subjects ? asset.subjects : []"
                                :postedTime="asset.created_at_formatted"
                                :tags="asset.tags"
                                :isAddedToWorkspace="asset.has_added_to_workspace"
                                :contentType="asset.content_type"
                                @refresh-asset-api="() => { fetchDocuments(); getStats(); }"
                            />
                    </div>

                    <!--rows layout view -->
                    <div v-if="!isGridDisplayPattern && !isLoading" :class="{'text-black bg-white': modeStore.selectedMode === 'light', 'bg-black text-white': modeStore.selectedMode === 'dark'}" class="min-w-[600px] bg-opacity-30 rounded-xl" >
                        <div class="grid grid-cols-12 py-4 text-base font-semibold px-6 rounded-t-xl" :class="{'bg-white': modeStore.selectedMode === 'light'}">
                            <div class="col-span-1 invisible">checkbox</div>
                            <div class="col-span-6 text-center">{{ $t('documentPage.title')}}</div>
                            <div class="col-span-3 text-center">{{ $t('documentPage.fileType')}}</div>
                            <div class="col-span-2 text-center">{{ $t('documentPage.favourite')}}</div>
                        </div>
                        <!-- <div v-show="isLoading" class="flex items-center justify-center w-full gap-2">
                            <div>{{ $t('general.loading')}}</div>
                            <span class="loading loading-bars loading-sm"></span>
                        </div> -->
                        <div v-for="document in documentList" :key="document.id" :class="{'hover:bg-white hover:bg-opacity-10': modeStore.selectedMode === 'dark', 'bg-white hover:bg-gray-200 hover:bg-opacity-20':modeStore.selectedMode === 'light'}" class="grid grid-cols-12 py-4 px-6">
                            <div class="col-span-1 text-center my-auto">
                                <input ref="checkbox" @click="showBarAndUpdateSelectedFile(document.id)" type="checkbox" :id="'checkbox-' + document.id" class="file-checkbox h-5 w-5" :checked="checkedItemStore.isChecked(document.id)"/>
                            </div>
                            <div class="col-span-6 text-center my-auto">
                                <div class="flex gap-2 cursor-pointer">
                                    <div v-if="document && document.thumbnails && document.thumbnails.length > 0">
                                        <img @click.prevent="goToDocument(document.id)" :src="document.thumbnails[0]?.url" alt="course img" class="max-h-16 rounded-md">
                                    </div>
                                    <div v-else class="h-16 w-16 min-h-16 max-h-16 rounded-md bg-white bg-opacity-20"></div>

                                    <div class="my-auto">
                                        <div @click.prevent="goToDocument(document.id)" class="my-auto font-semibold">{{ document.title }}</div>
                                        <div class="flex overflow-hidden">
                                            <div v-if="document.subjects && document.subjects.length > 0">
                                                <div v-for="(subject, index) in document.subjects" :key="subject.id" class="flex overflow-hidden">
                                                    <div class="cursor-pointer" @click="goToDocumentPageWithSubjectFilter(subject.id)">{{ subject.name }}</div><span v-if="index < document.subjects.length - 1">, </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-span-3 text-center my-auto">
                                <div             
                                    :class="{
                                        'bg-[#FEF3C7] text-[#92400E]': document.content_type === 1,
                                        'bg-green-100 text-green-800': document.content_type === 2,
                                        'bg-gray-100 text-gray-800': document.content_type === 3
                                    }" class="badge badge-lg border-0 rounded-xl">
                                        {{ getContentTypeLabel(document.content_type) }}
                                </div>
                            </div>

                            <div class="col-span-2 text-center my-auto mx-auto">
                                <div class="flex gap-2">
                                    <div v-if="document.has_added_to_workspace" @click="removeFromWorkspace(document.id)"  class="cursor-pointer">
                                        <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                                        <svg v-show="!isAddRemoveWorkspaceLoading" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                            <path d="M17.6257 3.73047C15.7402 3.73047 14.0527 4.64714 13.0007 6.05339C11.9486 4.64714 10.2611 3.73047 8.37565 3.73047C5.17773 3.73047 2.58398 6.33464 2.58398 9.55338C2.58398 10.793 2.7819 11.9388 3.12565 13.0013C4.77148 18.2096 9.8444 21.3242 12.3548 22.1784C12.709 22.3034 13.2923 22.3034 13.6465 22.1784C16.1569 21.3242 21.2298 18.2096 22.8757 13.0013C23.2194 11.9388 23.4173 10.793 23.4173 9.55338C23.4173 6.33464 20.8236 3.73047 17.6257 3.73047Z" :fill="strokeColor"/>
                                        </svg>
                                    </div>
                                    <div v-else @click="addToWorkspace(document.id)" class="cursor-pointer text-black" @mouseover="document.isHovered = true" @mouseout="document.isHovered = false">
                                        <div v-show="isAddRemoveWorkspaceLoading" class="spinner" :style="{borderLeftColor: strokeColor}"></div>
                                        <svg v-show="!isAddRemoveWorkspaceLoading" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                            <path d="M13.1465 21.6798C12.7923 21.8048 12.209 21.8048 11.8548 21.6798C8.83398 20.6486 2.08398 16.3465 2.08398 9.05485C2.08398 5.8361 4.67773 3.23193 7.87565 3.23193C9.77148 3.23193 11.4486 4.1486 12.5006 5.56527C13.5527 4.1486 15.2402 3.23193 17.1256 3.23193C20.3236 3.23193 22.9173 5.8361 22.9173 9.05485C22.9173 16.3465 16.1673 20.6486 13.1465 21.6798Z" :stroke="isHovered ? strokeColor : modeStrokeColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else-if="!isLoading && totalItems == 0">
                    <!-- <div 
                        class="rounded-xl p-[30px] bg-transparent"
                        :class="{'bg-transparent text-black': modeStore.selectedMode === 'light', 'bg-custom-blue-gray text-white': modeStore.selectedMode === 'dark'}"
                    >
                        <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('title.fileList')}}</h2>
                    </div> -->

                    <div :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}" class="flex flex-col items-center justify-center my-auto py-36">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="81" viewBox="0 0 80 81" fill="none">
                            <rect y="0.5" width="80" height="80" rx="20" fill="#E5E7EB"/>
                            <path d="M56.6667 37.6667V46C56.6667 54.3334 53.3333 57.6667 45 57.6667H35C26.6667 57.6667 23.3333 54.3334 23.3333 46V36C23.3333 27.6667 26.6667 24.3334 35 24.3334H43.3333" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M56.6667 37.6667H50C45 37.6667 43.3333 36 43.3333 31V24.3334L56.6667 37.6667Z" stroke="black" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div class="text-center py-6 text-xl font-bold">{{ $t('documentPage.noFileInCollection')}}</div>
                    </div>
                </div>

                <!-- Pagination -->
                <div class="">
                    <CustomPagination
                        :showingFrom="showingFrom"
                        :showingTo="showingTo"
                        :totalItems="totalItems"
                        :totalPages="totalPages"
                        :currentPage="currentPage"
                        :itemsPerPage="itemsPerPage"
                        @change-page="changePage"
                    />
                </div>
            </section>
        </div>
    </div>
</template>