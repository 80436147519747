<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import useIsMobile from '../composables/isMobile.js';
import { useModeStore } from '../store/mode.js';
import { useTranslation } from "i18next-vue";
// import { useThemeStore } from '../store/theme.js';
import { useActivePageStore } from '../store/activePageTab.js';
import { useUserStore } from '../store/user.js'; 
import { useNotificationTopBarStore } from '../store/notificationTopBar.js';
import { getModeStrokeColor } from '../utils/commonFunctions.js';
import ThemeButton from '../components/ThemeButton.vue';
import PageNavigator from '../components/PageNavigator.vue';
import NotificationTopBar from '../components/NotificationTopBar.vue';

const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

const { t } = useTranslation();
const { isMobile } = useIsMobile();
const modeStore = useModeStore();
// const themeStore = useThemeStore();
const userStore = useUserStore();
const notificationTopBarStore = useNotificationTopBarStore();
const modeStrokeColor = getModeStrokeColor();
const breadcrumbs = [
  { text: 'Laman Utama', to: { name: 'Dashboard Page' } },
  { text: t('sideMenu.Tetapan') },
];

const userData = ref(null);
const initialUserData = ref(null);
const errors = ref(null);
const selectedThumbnailFile = ref(null);

const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    selectedThumbnailFile.value = file;

    const reader = new FileReader();
    reader.onload = (e) => {
        userData.value.avatar_url = e.target.result;
    };
    reader.readAsDataURL(file);
}

const triggerFileInput = () => {
    fileInputRef.value.click();
};

const fileInputRef = ref(null);

const validateData = () => {
    // Clear previous errors
    errors.value = {};

    // Validate required fields
    if (!schoolStreamId.value) {
        errors.value.stream = "This field is required";
    }

    if (!userData.value.gender) {
        errors.value.gender = "This field is required";
    }

    // Return true if there are no errors, false otherwise
    return Object.keys(errors.value).length === 0;
};


const submitEditUserProfile = () => {
    // Run validation first
    const isValid = validateData();
    
    // If validation fails, stop the submission
    if (!isValid) {
        return;
    }

    const formData = new FormData();
    formData.append('name', userData.value.name);
    formData.append('stream', schoolStreamId.value);
    formData.append('gender', userData.value.gender);
    if (selectedThumbnailFile.value) {
        const blob = new Blob([selectedThumbnailFile.value], { type: selectedThumbnailFile.value.type });
        formData.append('avatar', blob, selectedThumbnailFile.value.name);
    }

    formData.append('_method', 'put');
    axios.post(`api/v1/user`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    .then((response) => {
        userData.value = response.data;
        userStore.login(response.data);

        // Set up notification after successful addition
        notificationTopBarStore.setNotificationMessage('Berjaya Disimpan!');
        notificationTopBarStore.setNotificationType('updateUserProfile');
        notificationTopBarStore.setIsShowNotificationTopBar(true);
    })
    .catch((error) => {
        console.log(error);
        errors.value = error.response.data.errors;
    }); 
}

const cancelEditUserProfile = () => {
    if (initialUserData.value) {
        userData.value = deepClone(initialUserData.value);
    }
}

// const formattedUserGroups = computed(() => {
//     if (userData.value && userData.value.media_group) {
//         return userData.value.media_group.map(group => group.name).join(', ');
//     }
//     return '';
// });

//school names
// const schoolNames = [
//   'SEKOLAH MENENGAH KEBANGSAAN HAMID KHAN',
//   'SEKOLAH MENENGAH KEBANGSAAN TROLAK SELATAN',
//   'SEKOLAH MENENGAH KEBANGSAAN (FELDA) BESOUT',
//   'SEKOLAH MENENGAH KEBANGSAAN SUNGAI KERUIT',
//   'SEKOLAH MENENGAH KEBANGSAAN AIR KUNING',
//   'SEKOLAH MENENGAH KEBANGSAAN CHENDERIANG',
//   'KOLEJ TINGKATAN ENAM TAPAH',
//   'SEKOLAH MENENGAH KEBANGSAAN BIDOR',
//   'SEKOLAH MENENGAH SAINS TAPAH',
//   'SEKOLAH MENENGAH KEBANGSAAN SERI MANJUNG',
//   'SEKOLAH MENENGAH KEBANGSAAN AHMAD BOESTAMAM',
//   'SEKOLAH MENENGAH KEBANGSAAN PANGKALAN TLDM',
//   'SEKOLAH MENENGAH KEBANGSAAN BATU SEPULUH',
//   'SEKOLAH MENENGAH KEBANGSAAN PANTAI REMIS',
//   'SEKOLAH MENENGAH KEBANGSAAN SERI SAMUDERA',
//   'SEKOLAH MENENGAH KEBANGSAAN CHANGKAT BERUAS',
//   'SEKOLAH MENENGAH KEBANGSAAN KG. DATO\' SERI KAMARUDDIN',
//   'SEKOLAH MENENGAH KEBANGSAAN RAJA CHULAN',
//   'SEKOLAH MENENGAH KEBANGSAAN JALAN PASIR PUTEH',
//   'SEKOLAH MENENGAH KEBANGSAAN GUNUNG RAPAT',
//   'SEKOLAH MENENGAH KEBANGSAAN RAJA PERMAISURI BAINUN',
//   'SEKOLAH MENENGAH KEBANGSAAN DATO\' HJ. MOHD TAIB',
//   'SEKOLAH MENENGAH KEBANGSAAN SERI KELEDANG',
//   'SEKOLAH MENENGAH KEBANGSAAN KG. DATO\' AHMAD SAID',
//   'SEKOLAH MENENGAH KEBANGSAAN KG PASIR PUTEH',
//   'SEKOLAH MENENGAH KEBANGSAAN SERI AMPANG',
//   'SEKOLAH MENENGAH KEBANGSAAN JALAN TASEK',
//   'SEKOLAH MENENGAH KEBANGSAAN SIMPANG PULAI'
// ];

// const searchQuery = ref('');
// const showSchoolNamesDropdown = ref(false);

// const filteredSchoolNames = computed(() => {
//   return schoolNames.filter(schoolName => schoolName.toLowerCase().includes(searchQuery.value.toLowerCase()));
// });

// const selectSchool = (schoolName) => {
//   userData.value.schoolName = schoolName;
//   searchQuery.value = schoolName;
//   showSchoolNamesDropdown.value = false;
// };

//tahap
// const levels = [
//   'Pra Sekolah',
//   'Menengah Atas',
//   'Menengah Bawah',
// ];

//Tingkatan/Tahun
// const grades = [
//     'Tahun 1',
//     'Tahun 2',
//     'Tahun 3',
//     'Tahun 4',
//     'Tahun 5',
//     'Tahun 6',
//     'Tingkatan 1',
//     'Tingkatan 2',
//     'Tingkatan 3',
//     'Tingkatan 4',
//     'Tingkatan 5',
//     'Tingkatan 6',
// ]

//stream
const streams = ref([]);
const schoolStreamId = computed({
      get() {
        return userData.value.school_stream ? userData.value.school_stream.id : null;
      },
      set(value) {
        if (userData.value.school_stream) {
          userData.value.school_stream.id = value;
        } else {
          userData.value.school_stream = { id: value };
        }
      }
    });

const getSchoolStreams = () => {
    axios.get(`api/v1/school_streams`).then(response => {
        streams.value = response.data.school_stream;
    }).catch(error => {
        console.error('Failed to fetch school streams:', error);
    });
}

const revertUpdateProfile = () => {
    userData.value = deepClone(initialUserData.value);
    notificationTopBarStore.setIsShowNotificationTopBar(false);
}

const activePageStore = useActivePageStore();
onMounted(() => {
    activePageStore.setSelectedActivePage(12)
    if (userStore.user) {
        initialUserData.value = deepClone(userStore.user);
        userData.value = userStore.user;

        if (!userData.value.school) {
            userData.value.school = {};
            userData.value.school.name = "";
        }
        if (!userData.value.school_stage) {
            userData.value.school_stage = {};
            userData.value.school_stage.name = "";
        }
        if (!userData.value.media_group || userData.value.media_group.length === 0) {
            userData.value.media_group = [{ name: '' }];  
        }
        if (!userData.value.gender) {
            userData.value.gender = '1';
        }
        if (userStore.user.avatar_url) {
            userData.value.avatar_url = userStore.user.avatar_url
        }
    }
    getSchoolStreams();
});

</script>

<template>
    <div class="min-h-screen">
        <NotificationTopBar v-show="notificationTopBarStore.isShowNotificationTopBar" @cancel-action="revertUpdateProfile" />
        <page-navigator :breadcrumbs="breadcrumbs" />
        <div :class="{'text-black': modeStore.selectedMode === 'light', ' text-white': modeStore.selectedMode === 'dark'}" class="flex justify-between justify-items-center my-auto py-4 px-[30px]">
            <h2 :class="{'mobile-title': isMobile, 'page-top-title': !isMobile}">{{ $t('setting.profileSetting') }}</h2>
        </div>
        <section 
            v-if="userData"
            class="flex flex-col gap-8 rounded-xl p-[30px]"
        > 
            <div>
                <!-- <div class="flex gap-2">
                    <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('setting.profileSetting') }}</h2>
                </div> -->
            </div>
            <div class="md:grid md:grid-cols-12 gap-4">
                <div id="settings-left" class="col-span-2 ml-0 mr-10">
                    <div class="rounded-full relative min-h-[100px] w-[100px] h-[100px] lg:w-[120px] lg:h-[120px] xl:w-[140px] xl:h-[140px] items-center justify-center mx-auto m-2">
                        <!-- avatar image -->
                        <img v-if="userData.avatar_url"
                            :class="modeStore.selectedMode === 'dark' ? 'bg-black shadow-gray-50 shadow-sm' : 'bg-gray-200' "
                            :src="userData.avatar_url" alt="avatar" class="w-full h-full object-cover rounded-full">
                        
                        <!-- placeholder when no avatar -->
                        <div v-if="!userData.avatar_url"
                        :class="modeStore.selectedMode === 'dark' ? 'bg-black' : 'bg-gray-200' "
                        class="w-full h-full rounded-full flex flex-col items-center justify-center border-2 border-dashed border-gray-400">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.87533 10.9167C11.0259 10.9167 11.9587 9.98393 11.9587 8.83333C11.9587 7.68274 11.0259 6.75 9.87533 6.75C8.72473 6.75 7.79199 7.68274 7.79199 8.83333C7.79199 9.98393 8.72473 10.9167 9.87533 10.9167Z" :stroke="modeStore.selectedMode === 'dark' ? 'white' : 'black'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M14.0413 2.58594H9.87467C4.66634 2.58594 2.58301 4.66927 2.58301 9.8776V16.1276C2.58301 21.3359 4.66634 23.4193 9.87467 23.4193H16.1247C21.333 23.4193 23.4163 21.3359 23.4163 16.1276V10.9193" :stroke="modeStore.selectedMode === 'dark' ? 'white' : 'black'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M16.9062 5.71094H22.6354" :stroke="modeStore.selectedMode === 'dark' ? 'white' : 'black'" stroke-width="1.5" stroke-linecap="round"/>
                            <path d="M19.7705 8.57292V2.84375" :stroke="modeStore.selectedMode === 'dark' ? 'white' : 'black'" stroke-width="1.5" stroke-linecap="round"/>
                            <path d="M3.28125 20.2422L8.41667 16.7943C9.23958 16.2422 10.4271 16.3047 11.1667 16.9401L11.5104 17.2422C12.3229 17.9401 13.6354 17.9401 14.4479 17.2422L18.7812 13.5234C19.5937 12.8255 20.9063 12.8255 21.7188 13.5234L23.4167 14.9818" :stroke="modeStore.selectedMode === 'dark' ? 'white' : 'black'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span 
                            :class="modeStore.selectedMode === 'dark' ? 'text-white' : 'text-black'"
                            class="text-lg">Muat naik</span>
                            <span class="text-gray-400 text-sm">atau seret & lepas gambar</span>
                        </div>
                        
                        <input type="file" ref="fileInputRef" class="hidden" @change="handleFileInputChange">
                        
                        <div @click="triggerFileInput" class="absolute inset-0 rounded-full cursor-pointer flex items-center justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity duration-300">
                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.87533 10.9167C11.0259 10.9167 11.9587 9.98393 11.9587 8.83333C11.9587 7.68274 11.0259 6.75 9.87533 6.75C8.72473 6.75 7.79199 7.68274 7.79199 8.83333C7.79199 9.98393 8.72473 10.9167 9.87533 10.9167Z" :stroke="modeStrokeColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M14.0413 2.58594H9.87467C4.66634 2.58594 2.58301 4.66927 2.58301 9.8776V16.1276C2.58301 21.3359 4.66634 23.4193 9.87467 23.4193H16.1247C21.333 23.4193 23.4163 21.3359 23.4163 16.1276V10.9193" :stroke="modeStrokeColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.9062 5.71094H22.6354" :stroke="modeStrokeColor" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M19.7705 8.57292V2.84375" :stroke="modeStrokeColor" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M3.28125 20.2422L8.41667 16.7943C9.23958 16.2422 10.4271 16.3047 11.1667 16.9401L11.5104 17.2422C12.3229 17.9401 13.6354 17.9401 14.4479 17.2422L18.7812 13.5234C19.5937 12.8255 20.9063 12.8255 21.7188 13.5234L23.4167 14.9818" :stroke="modeStrokeColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="col-span-1"></div>

                <div
                    id="settings-right"
                    :class="{'bg-white bg-opacity-40 text-black': modeStore.selectedMode === 'light', 'bg-black bg-opacity-10 text-white': modeStore.selectedMode === 'dark'}"
                    class="col-span-9 text-left rounded-xl p-4">
                    <p class="text-xl pb-6">{{ $t('setting.personalInformation') }}</p>
                    <div class="grid md:grid-cols-2 gap-4 mb-2">
                        <div>
                            <label for="name" class="block mb-1">{{ $t('setting.fullName') }}</label>
                            <input v-model="userData.name" type="text" id="name" placeholder="" :class="modeStore.selectedMode === 'dark'? 'border-gray-600': ''" class="bg-transparent block w-full px-4 py-2 border rounded-md focus:outline-none focus:border-blue-500">
                        </div>
                        <div class="relative">
                            <label for="schoolName" class="block mb-1">{{ $t('setting.schoolName') }}</label>
                            <input disabled v-model="userData.school.name" type="email" id="email" placeholder=""
                            :class="modeStore.selectedMode === 'dark'? 'border-gray-600 bg-gray-900 text-gray-500': 'bg-gray-200 text-gray-400'" 
                            class="block w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:border-blue-500">
                            
                            <!-- Input field for searching -->
                            <!-- <input 
                            v-model="searchQuery"
                            @input="showSchoolNamesDropdown = true"
                            :class="modeStore.selectedMode === 'dark' ? 'border-gray-600' : ''" 
                            class="bg-transparent border rounded-md focus:outline-none focus:border-blue-500 w-full px-4 py-2 text-sm md:text-base"
                            placeholder=
                            /> -->

                            <!-- Dropdown for filtered school names -->
                            <!-- <ul v-if="showSchoolNamesDropdown && filteredSchoolNames.length" class="absolute top-18 border rounded-md bg-white dark:bg-gray-800 mt-1 max-h-60 overflow-auto">
                                <li 
                                    v-for="(schoolName, index) in filteredSchoolNames" 
                                    :key="index" 
                                    @click="selectSchool(schoolName)" 
                                    class="px-4 py-2 cursor-pointer hover:bg-blue-500 hover:text-white dark:hover:bg-gray-600"
                                >
                                    {{ schoolName }}
                                </li>
                            </ul> -->
                        </div>
                    </div>
                    <div class="grid md:grid-cols-2 gap-4 mb-2">
                        <div>
                            <label for="level" class="block mb-1">{{ $t('setting.level') }}</label>
                            <input disabled v-model="userData.school_stage.name" type="email" id="email" placeholder=""
                            :class="modeStore.selectedMode === 'dark'? 'border-gray-600 bg-gray-900 text-gray-500': 'bg-gray-200 text-gray-400'" 
                            class="block w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:border-blue-500">
                            <!-- <select v-model="userData.level" :class="modeStore.selectedMode === 'dark'? 'border-gray-600': ''" class="bg-transparent border rounded-md focus:outline-none focus:border-blue-500 w-full px-4 py-2 text-sm md:text-base">
                                <option v-for="(level, index) in levels" :key="index" :value="level">{{ level }}</option>
                             </select> -->
                        </div>
                        <div>
                            <label for="grade" class="block mb-1">{{ $t('setting.grade') }}</label>
                            <!-- <select v-model="userData.grade" :class="modeStore.selectedMode === 'dark'? 'border-gray-600': ''" class="bg-transparent border rounded-md focus:outline-none focus:border-blue-500 w-full px-4 py-2 text-sm md:text-base">
                                <option v-for="(grade, index) in grades" :key="index" :value="grade">{{ grade }}</option>
                             </select> -->
                             <input disabled v-model="userData.media_group[0].name" type="text" id="grade" placeholder=""
                            :class="modeStore.selectedMode === 'dark'? 'border-gray-600 bg-gray-900 text-gray-500': 'bg-gray-200 text-gray-400'" 
                            class="block w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:border-blue-500">
                        </div>
                    </div>
                    <div class="grid md:grid-cols-2 gap-4 mb-2">
                        <div v-show="streams && streams.length > 0">
                            <label for="stream" class="block mb-1">{{ $t('setting.stream') }}<span class="text-red-600">*</span></label>
                            <select v-model="schoolStreamId" :class="modeStore.selectedMode === 'dark'? 'border-gray-600': ''" class="bg-transparent border rounded-md focus:outline-none focus:border-blue-500 w-full px-4 py-2 text-sm md:text-base">
                                <option v-for="stream in streams" :key="stream.id" :value="stream.id">{{ stream.name }}</option>
                             </select>
                             <p v-if="errors && errors.stream" class="text-red-600 text-sm mt-1">{{ errors.stream }}</p>
                        </div>
                        <div>
                            <label for="gender" class="block mb-1">{{ $t('setting.gender') }}<span class="text-red-600">*</span></label>
                            <select v-model="userData.gender" :class="modeStore.selectedMode === 'dark'? 'border-gray-600': ''" class="bg-transparent border rounded-md focus:outline-none focus:border-blue-500 w-full px-4 py-2">
                                <option value="1">{{ $t('setting.male') }}</option>
                                <option value="2">{{ $t('setting.female') }}</option>
                            </select>
                            <p v-if="errors && errors.gender" class="text-red-600 text-sm mt-1">{{ errors.gender }}</p>
                        </div>
                    </div>
                    <div class="mb-2">
                        <label for="email" class="block mb-1">{{ $t('setting.emailAddress') }}</label>
                        <input disabled v-model="userData.email" type="email" id="email" placeholder=""
                            :class="modeStore.selectedMode === 'dark'? 'border-gray-600 bg-gray-900 text-gray-500': 'bg-gray-200 text-gray-400'" 
                            class="block w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:border-blue-500">
                    </div>
                </div>
            </div>

            <div class="flex gap-2 justify-end py-4">
                <ThemeButton
                    variant="quaternary"
                    size="medium" 
                    @click="cancelEditUserProfile"
                >
                    {{ $t('general.cancel') }}
                </ThemeButton>
                
                <ThemeButton
                    variant="primary"
                    size="medium"
                    @click="submitEditUserProfile"
                >
                    {{ $t('general.save') }}
                </ThemeButton>
            </div>
        </section>
    </div>
</template>