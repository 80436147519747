import { defineStore } from 'pinia'

export const useActivePageStore = defineStore('selectedActivePage', {
    state: () => {
      return {
        selectedActivePage: 1,
        selectedActivePageTitle: '',
        selectedActivePageName: '',
      }
    },
    actions: {
      setSelectedActivePage(page, title, name) {
        this.selectedActivePage = page
        this.selectedActivePageTitle = title
        this.selectedActivePageName = name
      }
    },
    persist: true,
  })