<script setup>
import { ref, onMounted, watch, defineProps, nextTick, onBeforeUnmount } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import { useModeStore } from '../store/mode.js';
import useIsMobile from '../composables/isMobile.js';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import axios from 'axios';
import { useActivePageStore } from '../store/activePageTab.js';
import { useUserStore } from '../store/user.js';
import { usePlaybackStore } from '../store/playbackStore.js';
import { useAllSearchResultStore } from '../store/allSearchResult.js';
import { useRoute } from 'vue-router';
import { getContentTypeLabel, getStrokeColor } from '../utils/commonFunctions.js';

// const router = useRouter();

import PageNavigator from '../components/PageNavigator.vue';
import ThemeButton from '@/components/ThemeButton.vue';

const props = defineProps({
  id: Number,
  isTeacherPlaylist: Boolean,
  assetBroadcastId: Number,
});
const isTeacherPlaylist = ref(props.isTeacherPlaylist === 'true');
const userStore = useUserStore();
const modeStore = useModeStore();
const route = useRoute();
const playbackStore = usePlaybackStore();
const activePageStore = useActivePageStore();
const allSearchResultStore = useAllSearchResultStore();
const { isMobile } = useIsMobile();
const strokeColor = getStrokeColor();

const playlistData = ref({});
const playlistAssetBroadcastList = ref([]);
const currentAssetBroadcastIndex = ref(0);
const loading = ref(false);
const loadingMedia = ref(false);
const player = ref(null);
const isExpanded = ref(false);

const pdfBlobUrl = ref('');
const isPdfLoading = ref(false);
const isPdfBlobCreating = ref(false);

const contentType = {
  video: 1,
  html: 2,
  pdf: 3,
};
const breadcrumbs = ref([]);

const createPdfBlob = async (url) => {
  try {
    isPdfLoading.value = true;
    isPdfBlobCreating.value = true;
    
    // Clean up old blob URL if it exists
    if (pdfBlobUrl.value) {
      URL.revokeObjectURL(pdfBlobUrl.value);
      pdfBlobUrl.value = '';
    }

    const response = await fetch(url);
    const blob = await response.blob();
    
    // Check if we're still creating this PDF (hasn't been cancelled)
    if (isPdfBlobCreating.value) {
      const blobUrl = URL.createObjectURL(
        new Blob([blob], { type: 'application/pdf' })
      );
      return blobUrl;
    }
    return '';
  } catch (error) {
    console.error('Error creating PDF blob:', error);
    return url; // Fallback to direct URL
  } finally {
    isPdfLoading.value = false;
    isPdfBlobCreating.value = false;
  }
};

const cleanupContent = () => {
  // Cancel any ongoing PDF blob creation
  isPdfBlobCreating.value = false;
  
  // Clean up existing PDF blob
  if (pdfBlobUrl.value) {
    URL.revokeObjectURL(pdfBlobUrl.value);
    pdfBlobUrl.value = '';
  }
  
  // Clean up video player
  // if (player.value) {
  //   try {
  //     player.value.pause();
  //     player.value.dispose();
  //   } catch (error) {
  //     console.error('Error disposing player:', error);
  //   }
  //   player.value = null;
  // }
  const currentAssetBroadcast = playlistAssetBroadcastList.value[currentAssetBroadcastIndex.value]; 

  if (currentAssetBroadcast.content_type !== contentType.video) {
    if (player.value) {
      try {
        player.value.pause();
        player.value.dispose();
      } catch (error) {
        console.error('Error disposing player:', error);
      }
      player.value = null;
    }
  }

};

const switchContent = async (index) => {
  try {
    loadingMedia.value = true;
    // First cleanup existing content
    cleanupContent();
    
    // Update the index
    currentAssetBroadcastIndex.value = index;
    
    // Wait for DOM update
    await nextTick();
    
    const currentAssetBroadcast = playlistAssetBroadcastList.value[index];
    
    if (currentAssetBroadcast.content_type === contentType.video) {
      await changeVideoSource();
    } else if (currentAssetBroadcast.content_type === contentType.pdf) {
      const newBlobUrl = await createPdfBlob(currentAssetBroadcast.file_preview_path);
      if (newBlobUrl) {
        pdfBlobUrl.value = newBlobUrl;
      }
    }
    // HTML content type handled automatically by iframe
  } catch (error) {
    console.error('Error switching content:', error);
  } finally {
    loadingMedia.value = false;
  }
};


const updateCurrentlyPlayingPlaylist = (playlist, document) => {
  userStore.setCurrentlyPlayingPlaylist(playlist, document);
};

const fetchPlaylistDetails = () => {
  const apiUrl = isTeacherPlaylist.value
    ? `/api/v1/playlists/${props.id}`
    : `/api/v1/user-playlists/${props.id}`;

  axios
    .get(apiUrl)
    .then((response) => {
      playlistData.value = response.data;

      // Generate breadcrumbs after fetching playlist data
      breadcrumbs.value = [
        { text: 'Laman Utama', to: { name: 'Dashboard Page' } },
        { text: 'Playlist', to: { name: 'Playlist Page' } },
        { text: 'Senarai Playlist', to: { name: 'Playlist Page' } },
        {
            text: playlistData.value.title || 'Playlist Detail',
            to: {
            name: 'Playlist Detail',
            query: { id: playlistData.value.id, isTeacherPlaylist: isTeacherPlaylist.value }
            }
        },
        { text: 'Preview Playlist' },
        ];
    })
    .catch((error) => {
      console.error('Failed to fetch playlist details:', error);
    });
};

const fetchPlaylistAssetBroadcasts = async () => {
  const apiUrl = isTeacherPlaylist.value
    ? `/api/v1/playlists/${props.id}/asset-broadcasts`
    : `/api/v1/user-playlists/${props.id}/asset-broadcasts`;

  try {
    const response = await axios.get(apiUrl);
    playlistAssetBroadcastList.value = response.data.data;
    loading.value = false;

    // Handle initial content loading
    await nextTick();
    
    // Determine initial index
    let initialIndex = 0;
    if (props.assetBroadcastId) {
      const assetBroadcastIndex = playlistAssetBroadcastList.value.findIndex(
        (assetBroadcast) => assetBroadcast.id === props.assetBroadcastId
      );
      if (assetBroadcastIndex !== -1) {
        initialIndex = assetBroadcastIndex;
      }
    } else if (route.query.assetId) {
      const assetBroadcastIndex = playlistAssetBroadcastList.value.findIndex(
        (assetBroadcast) => assetBroadcast.id === Number(route.query.assetId)
      );
      if (assetBroadcastIndex !== -1) {
        initialIndex = assetBroadcastIndex;
      }
    }

    // Set index and load initial content
    currentAssetBroadcastIndex.value = initialIndex;
    await switchContent(initialIndex);
    
  } catch (error) {
    console.error('Failed to fetch playlist asset broadcasts:', error);
    loading.value = false;
  }
};


// Update backend playlist progress
const updateAssetBroadcastProgress = (assetBroadcastId) => {
    const apiUrl = isTeacherPlaylist.value
        ? `/api/v1/playlists/${props.id}/progress`
        : `/api/v1/user-playlists/${props.id}/progress`;

    const current_seconds = (player.value && player.value.currentTime()) ? player.value.currentTime() : 0;
    axios
        .post(apiUrl, { asset_broadcast_id: assetBroadcastId, current_seconds: current_seconds })
        .then(() => {
        })
        .catch((error) => {
            console.error('Failed to update asset broadcast progress:', error);
        });
};

// Initialize Player
const initPlayer = () => {
  const currentAssetBroadcast = playlistAssetBroadcastList.value[currentAssetBroadcastIndex.value];

  if (currentAssetBroadcast.content_type === contentType.video) {
    // Dispose existing player if any
    if (player.value) {
      player.value.dispose();
      player.value = null;
    }

    // Wait for DOM update
    nextTick(() => {
      try {
        const videoElement = document.querySelector('#mainVideo');
        if (!videoElement) {
          console.error('Video element not found');
          return;
        }

        player.value = videojs(videoElement, {
          sources: [{ 
            src: currentAssetBroadcast.file_preview_path, 
            type: 'video/mp4' 
          }],
          autoplay: 'muted',
          controls: true,
          disablePictureInPicture: true,
          fluid: true,
        });

        // Event listeners
        player.value.on('timeupdate', () => {
          playbackStore.updateCurrentTime(player.value.currentTime());
          if (player.value.currentTime() % 10 === 0) {
            updateAssetBroadcastProgress(currentAssetBroadcast.id);
          }
        });

        player.value.on('play', () => {
          playbackStore.updatePlayingState(true);
        });

        player.value.on('pause', () => {
          playbackStore.updatePlayingState(false);
          updateAssetBroadcastProgress(currentAssetBroadcast.id);
        });

        player.value.on('durationchange', () => {
          playbackStore.updateDuration(player.value.duration());
        });

        player.value.on('ended', () => {
          updateAssetBroadcastProgress(currentAssetBroadcast.id);
          playNextAssetBroadcast();
        });

        updateCurrentlyPlayingPlaylist(playlistData.value, currentAssetBroadcast);

      } catch (error) {
        console.error('Error initializing video player:', error);
      }
    });
  }
};

// change video source
const changeVideoSource = async () => {
  const currentAssetBroadcast = playlistAssetBroadcastList.value[currentAssetBroadcastIndex.value];

  if (currentAssetBroadcast.content_type === contentType.video) {
    try {
      if (!player.value) {
        // Initialize new player if none exists
        nextTick(() => {
          initPlayer();
        });
      } else {
        // If player exists, properly update the source
        player.value.src({
          type: 'video/mp4',
          src: currentAssetBroadcast.file_preview_path
        });
        player.value.load(); // Force reload of video
        player.value.play().catch(error => {
          console.error('Error playing video:', error);
        });
      }
    } catch (error) {
      console.error('Error changing video source:', error);
      // Reinitialize player if error occurs
      if (player.value) {
        player.value.dispose();
        player.value = null;
      }
      nextTick(() => {
        initPlayer();
      });
    }
  }
};

// Previous
const playPreviousAssetBroadcast = () => {
  if (currentAssetBroadcastIndex.value > 0) {
    switchContent(currentAssetBroadcastIndex.value - 1);
  }
};

const playNextAssetBroadcast = () => {
  if (currentAssetBroadcastIndex.value < playlistAssetBroadcastList.value.length - 1) {
    switchContent(currentAssetBroadcastIndex.value + 1);
  }
};

// watch if current asset broadcast index changes, then update progress
// and change video source if the selected asset is a video
watch(currentAssetBroadcastIndex, async (newIndex) => {
  const currentAssetBroadcast = playlistAssetBroadcastList.value[newIndex];
  
  // Update progress and playlist as before
  updateAssetBroadcastProgress(currentAssetBroadcast.id);
  updateCurrentlyPlayingPlaylist(playlistData.value, currentAssetBroadcast);

  // Handle different content types
  if (currentAssetBroadcast.content_type === contentType.video) {
    changeVideoSource();
  } else if (currentAssetBroadcast.content_type === contentType.pdf) {
    pdfBlobUrl.value = await createPdfBlob(currentAssetBroadcast.file_preview_path);
  }
});

// expand the iframe or video player to full and hide the playlist items
const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};

onMounted(async () => {
  activePageStore.setSelectedActivePage(10);
  allSearchResultStore.setShowSearchResult(false);
  loading.value = true;
  
  // Fetch data sequentially
  await fetchPlaylistDetails();
  await fetchPlaylistAssetBroadcasts();
});

watch(() => route.query.assetId, async (newAssetId) => {
  if (newAssetId && playlistAssetBroadcastList.value.length > 0 && !loading.value) {
    const assetBroadcastIndex = playlistAssetBroadcastList.value.findIndex(
      (assetBroadcast) => assetBroadcast.id === Number(newAssetId)
    );

    if (assetBroadcastIndex !== -1) {
      await switchContent(assetBroadcastIndex);
    }
  }
}, { immediate: false });

// router.beforeEach((to, from, next) => {
//   if (player.value) {
//     player.value.pause();
//     playbackStore.updatePlayingState(false);
//   }
//   next();
// });

onBeforeUnmount(() => {
  cleanupContent();
});

onBeforeRouteLeave(() => {
  cleanupContent();
});


</script>

<template>
    <div class="min-h-screen" :key="$route.fullPath">
      <page-navigator :breadcrumbs="breadcrumbs" />
      <section
        class="flex flex-col gap-8 rounded-xl p-[30px] bg-transparent"
        :class="{'text-black': modeStore.selectedMode === 'light', ' text-white': modeStore.selectedMode === 'dark'}"
      >
        <div v-show="loading" class="flex items-center justify-center w-full gap-2 my-4">
            <div>{{ $t('general.loading')}}</div>
            <span class="loading loading-bars loading-sm"></span>
        </div>
        <div
          class="flex items-center justify-between mb-4"
          :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
        >
          <!-- <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ playlistData.title }}</h2> -->
        </div>
  
        <div class="filament-main-content h-full w-full mx-auto grid" :class="{ 'md:grid-cols-3': !isExpanded, 'md:grid-cols-1': isExpanded }" :style="{ transition: 'grid-template-columns 0.3s ease-in-out' }">
          <div v-if="loadingMedia" class="col-span-12" :class="{ 'md:col-span-2': !isExpanded }">
            <div class="w-full h-full flex items-center justify-center">
              <div :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}" class="spinner-border" role="status">
                <span class="visually-hidden">{{ $t('general.loading')}}</span>
              </div>
            </div>
          </div>
  
          <div v-if="!loading && playlistAssetBroadcastList.length === 0" class="col-span-12 flex items-center justify-center">
            <p>No data available</p>
          </div>
  
          <div v-if="playlistAssetBroadcastList.length > 0" class="col-span-12" :class="{ 'md:col-span-2': !isExpanded }">
            <div class="flex justify-between mb-4">
              <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ playlistData.title }}</h2>
              <button @click="toggleExpand" v-if="!isExpanded">
                <svg width="50" height="49" viewBox="0 0 59 48" fill="none" xmlns="http://www.w3.org/2000/svg" @click="isShow = false; openContainer = true">
                  <g filter="url(#filter0_d_13440_20782)">
                  <rect x="2" y="1" width="55" height="44" rx="10" :fill=strokeColor />
                  <path d="M38.8749 32.3749L20.125 13.625M38.8751 13.625L20.125 32.3751" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                  <filter id="filter0_d_13440_20782" x="0" y="0" width="59" height="48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="1"/>
                  <feGaussianBlur stdDeviation="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13440_20782"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13440_20782" result="shape"/>
                  </filter>
                  </defs>
                </svg>
                
              </button>
              <button @click="toggleExpand" v-if="isExpanded">
                <svg width="59" height="48" viewBox="0 0 59 48" fill="none" xmlns="http://www.w3.org/2000/svg" @click="isShow = true; openContainer = false">
                <g filter="url(#filter0_d_13449_62103)">
                  <rect x="2" y="1" width="55" height="44" rx="10" :fill=strokeColor />
                  <path d="M39.6562 17.5111C39.6562 17.3027 39.5729 17.1048 39.4271 16.959L35.5521 13.084C35.25 12.7819 34.75 12.7819 34.4479 13.084C34.1458 13.3861 34.1458 13.8861 34.4479 14.1882L37 16.7402H20.125C19.6979 16.7402 19.3438 17.0944 19.3438 17.5215C19.3438 17.9486 19.6979 18.2923 20.125 18.2923H36.9896L34.4479 20.834C34.1458 21.1361 34.1458 21.6361 34.4479 21.9382C34.6042 22.0944 34.8021 22.1673 35 22.1673C35.1979 22.1673 35.3958 22.0944 35.5521 21.9382L39.4167 18.0736C39.4896 18.0007 39.5521 17.9069 39.5938 17.8027C39.5938 17.7923 39.5938 17.7819 39.6042 17.7715C39.6354 17.6882 39.6562 17.6048 39.6562 17.5111Z" fill="white"/>
                  <path d="M38.875 27.7083H22.0104L24.5521 25.1667C24.8542 24.8646 24.8542 24.3646 24.5521 24.0625C24.25 23.7604 23.75 23.7604 23.4479 24.0625L19.5833 27.9271C19.5104 28 19.4479 28.0937 19.4062 28.1979C19.4062 28.2083 19.4062 28.2188 19.3958 28.2292C19.3646 28.3125 19.3438 28.4063 19.3438 28.5C19.3438 28.7083 19.4271 28.9062 19.5729 29.0521L23.4479 32.9271C23.6042 33.0833 23.8021 33.1562 24 33.1562C24.1979 33.1562 24.3958 33.0833 24.5521 32.9271C24.8542 32.625 24.8542 32.125 24.5521 31.8229L22 29.2708H38.875C39.3021 29.2708 39.6562 28.9167 39.6562 28.4896C39.6562 28.0625 39.3021 27.7083 38.875 27.7083Z" fill="white"/>
                  </g>
                  <defs>
                  <filter id="filter0_d_13449_62103" x="0" y="0" width="59" height="48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="1"/>
                  <feGaussianBlur stdDeviation="1"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13449_62103"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13449_62103" result="shape"/>
                  </filter>
                  </defs>
                </svg>
              </button>
            </div>
            <!-- Video Player -->
            <div v-if="playlistAssetBroadcastList[currentAssetBroadcastIndex].content_type === contentType.video" 
                class="media-player-wrapper relative">
              <video id="mainVideo" class="video-js vjs-default-skin" width="100%" height="100%">
                <p class="vjs-no-js">
                  To view this video please enable JavaScript, and consider upgrading to a
                  web browser that supports HTML5 video
                </p>
              </video>
            </div>
            <!-- Document (PDF) -->
            <div v-if="playlistAssetBroadcastList[currentAssetBroadcastIndex].content_type === contentType.pdf" class="pdf-media-wrapper">
              <div v-if="isPdfLoading" class="flex justify-center items-center h-[580px]">
                <div class="loader"></div>
              </div>
              <div v-else class="h-[580px]">
                <object 
                  :data="pdfBlobUrl" 
                  type="application/pdf"
                  class="w-full h-full"
                >
                  <iframe
                    :src="pdfBlobUrl"
                    class="w-full h-full"
                    frameborder="0"
                  >
                    <p class="text-center p-4">
                      Unable to display PDF. 
                      <a 
                        :href="playlistAssetBroadcastList[currentAssetBroadcastIndex].file_preview_path" 
                        target="_blank"
                        class="text-blue-600 hover:text-blue-800 underline"
                      >
                        Open in new tab
                      </a>
                    </p>
                  </iframe>
                </object>
              </div>
            </div>
            <!-- Interactive (HTML) -->
            <div v-if="playlistAssetBroadcastList[currentAssetBroadcastIndex].content_type === contentType.html" class="html-media-wrapper">
              <iframe :src="playlistAssetBroadcastList[currentAssetBroadcastIndex].file_preview_path" width="100%" height="580" frameborder="0" style="border: 1px solid #000;"></iframe>
            </div>
          </div>
  
          <div v-if="!isExpanded && playlistAssetBroadcastList.length > 0" :class="{'text-black bg-white bg-opacity-20': modeStore.selectedMode === 'light', 'text-white bg-black bg-opacity-10': modeStore.selectedMode === 'dark'}" class="playlistContent col-span-12 md:col-span-1 flex flex-col md:p-4 fi-section rounded-xl shadow-sm ml-2">
            <div class="playlistContent-title mb-2">Playlist</div>
            <div :class="{'text-black': modeStore.selectedMode === 'light', 'text-white ': modeStore.selectedMode === 'dark'}" class="scrollable-playlist-content w-full text-sm py-4 rounded-lg bg-transparent">
              <div
                v-for="(item, index) in playlistAssetBroadcastList"
                :key="item.id"
                :class="{'bg-white bg-opacity-10': (currentAssetBroadcastIndex === index && modeStore.selectedMode === 'dark'), ' bg-white bg-opacity-20': (currentAssetBroadcastIndex === index && modeStore.selectedMode === 'light')}"
                class="flex flex-row items-center w-full h-24 mb-2 p-2 cursor-pointer rounded-md"
                @click="switchContent(index)"
              >
                <div class="thumbnail-wrapper max-h-[100px] w-10 md:w-12 xl:w-20 flex-shrink-0 relative overflow-hidden">
                  <div class="relative">
                    <img :src="item.thumbnails[0]?.url" alt="thumbnail" class="w-full" />
                    <div class="absolute inset-0 flex items-center justify-center">
                      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="13" cy="13" r="12.5" fill="#111827" fill-opacity="0.5"/>
                      <path d="M15.6432 12.6872C15.6432 13.9762 14.6015 15.0179 13.3125 15.0179C12.0234 15.0179 10.9818 13.9762 10.9818 12.6872C10.9818 11.3981 12.0234 10.3564 13.3125 10.3564C14.6015 10.3564 15.6432 11.3981 15.6432 12.6872Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M13.3125 18.0716C15.6107 18.0716 17.7526 16.7174 19.2435 14.3737C19.8294 13.4557 19.8294 11.9128 19.2435 10.9948C17.7526 8.65104 15.6107 7.29688 13.3125 7.29688C11.0143 7.29688 8.87242 8.65104 7.38153 10.9948C6.79559 11.9128 6.79559 13.4557 7.38153 14.3737C8.87242 16.7174 11.0143 18.0716 13.3125 18.0716Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                  </div>
                  
                  <p class="bg-black p-[2px] rounded-sm text-xs">{{ item.duration }}</p>
                </div>
                <div class="playlist-item-details ml-2 flex flex-col justify-between">
                  <span class="block truncate max-w-[250px] md:max-w-44 xl:max-w-56 font-bold mb-2">{{ item.title }}</span>
                  <div v-if="item.content_type" class="badge badge-lg border-0 rounded-xl h-auto text-sm"
                        :class="{
                        'bg-[#FEF3C7] text-[#92400E]': item.content_type === 1,
                        'bg-green-100 text-green-800': item.content_type === 2,
                        'bg-purple-100 text-purple-800': item.content_type === 3
                    }"
                    >
                    {{ getContentTypeLabel(item.content_type) }}
                    </div>
                  <!-- <span>{{ item.content_type_text }}</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div v-if="playlistAssetBroadcastList.length > 0" :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}">
          <span class="text-xl font-semibold">{{ playlistAssetBroadcastList[currentAssetBroadcastIndex].title }}</span>
          <div class="mt-2 flex flex-col h-fit rounded-md"  v-if="playlistAssetBroadcastList[currentAssetBroadcastIndex].description ">
            <div class="w-full">
              <p>{{ playlistAssetBroadcastList[currentAssetBroadcastIndex].description }}</p>
            </div>
          </div>
        </div>
  
        <div class="flex justify-between mt-4">
          <ThemeButton v-if="currentAssetBroadcastIndex > 0" @click="playPreviousAssetBroadcast">Previous</ThemeButton>
          <ThemeButton v-if="currentAssetBroadcastIndex < playlistAssetBroadcastList.length - 1" @click="playNextAssetBroadcast">Next</ThemeButton>
        </div>
      </section>
    </div>
  </template>
  
<style>
/* .media-active {
  background-color: rgba(0.0667, 0.0941, 0.1529, 0.2);
} */
.thumbnail-wrapper img {
  object-fit: cover;
}
.playlistContent {
  max-height: 580px;
}
.scrollable-playlist-content {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.playlist-item-details {
  word-break: break-all;
}
.video-js {
  height: 100% !important;
}

.pdf-media-wrapper {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  overflow: hidden;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>