<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import axios from 'axios';
import { useRouter } from 'vue-router'
import { useModeStore } from '../store/mode.js';
import { useThemeStore } from '../store/theme.js';
import useIsMobile from '../composables/isMobile.js';
import { useActivePageStore } from '../store/activePageTab.js';
import { useAddToPlaylistStore } from '../store/addToPlaylist.js';
import { useRemoveFromPlaylistStore } from '../store/removeFromPlaylist.js';
import { useNotificationTopBarStore } from '../store/notificationTopBar.js';
import { useTranslation } from "i18next-vue";

import PageNavigator from '../components/PageNavigator.vue';
// import PlaylistCard from '../components/PlaylistCard.vue'
import PlaylistCardSkeletonLoader from '../components/PlaylistCardSkeletonLoader.vue'
import DocumentsCard from '../components/DocumentsCard.vue'
import DocumentSkeletonCard from '@/components/DocumentSkeletonCard.vue';
import CreateEditPlaylistModal from '../components/CreateEditPlaylistModal.vue';
import AddOrCreatePlaylistTopBar from '../components/AddOrCreatePlaylistTopBar.vue';
import AddToPlaylistDropdown from '../components/AddToPlaylistDropdown.vue';
import TabList from '../components/TabsComponent.vue';
import PlaylistCardForTeacher from '@/components/PlaylistCardForTeacher.vue';
import RemoveFromPlaylistConfirmation from '../components/RemoveFromPlaylistConfirmation.vue';
import NotificationTopBar from '../components/NotificationTopBar.vue';
import CustomPagination from '../components/CustomPagination.vue';

const router = useRouter();
const modeStore = useModeStore();
const themeStore = useThemeStore();
const activePageStore = useActivePageStore();
const { isMobile } = useIsMobile();
const { t } = useTranslation();
const addToPlaylistStore = useAddToPlaylistStore();
const removeFromPlaylistStore = useRemoveFromPlaylistStore();
const notificationTopBarStore = useNotificationTopBarStore();

const playlistCards = ref([]);
const documentsCard = ref([])

const breadcrumbs = [
  { text: 'Laman Utama', to: { name: 'Dashboard Page' } },
  { text: t('title.workspace') },
];

const activeTab = ref('playlist');
const tabList = [
  { value: 'playlist', label: 'title.playlist' },
  { value: 'documents', label: 'title.document' },
];

watch(activeTab, (newTab) => {
    currentPage.value = 1;

  if (newTab === 'documents') {
    fetchWorkspaceAssetBroadcasts();
  }
  else{
    fetchWorkspacePlaylists();
  }
});

const currentPage = ref(1);
const itemsPerPage = ref(9); // Default itemsPerPage 10
const totalItems = ref(0);

const showingFrom = computed(() => {
    return (currentPage.value - 1) * itemsPerPage.value + 1;
});

const showingTo = computed(() => {
    // If this is the last page, return the total items, else return the current page * items per page
    return totalItems.value < currentPage.value * itemsPerPage.value ? totalItems.value : currentPage.value * itemsPerPage.value;
});

const totalPages = computed(() => {
    return Math.ceil(totalItems.value / itemsPerPage.value);
});

const changePage = (page) => {
    currentPage.value = page;
    if(activeTab.value == 'playlist'){
        fetchWorkspacePlaylists();
    }
    else{
        fetchWorkspaceAssetBroadcasts();
    }
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

const isAssetBroadcastsLoading = ref(true);
const fetchWorkspaceAssetBroadcasts = () => {
    isAssetBroadcastsLoading.value = true;
    axios.get('api/v1/workspaces/asset_broadcasts')
        .then((response) => {
            documentsCard.value = response.data.data;
            totalItems.value = documentsCard.value.length;
        })
        .catch((error) => {
            console.error('Failed to fetch workspace asset broadcasts:', error);
        })
        .finally(() => {
            isAssetBroadcastsLoading.value = false;
        });
}

const isPlaylistLoading = ref(true);
// const fetchWorkspacePlaylists = () => {
//     isPlaylistLoading.value = true;
//     axios.get('api/v1/workspaces/playlists')
//         .then((response) => {
//             playlistCards.value = response.data.data;
//         })
//         .catch((error) => {
//             console.error('Failed to fetch workspace playlists:', error);
//         })
//         .finally(() => {
//             isPlaylistLoading.value = false;
//         });
// }

const fetchWorkspacePlaylists = () => {
    isPlaylistLoading.value = true;

    axios.all([
        axios.get('api/v1/workspaces/playlists'),
        axios.get('api/v1/workspaces/user-playlists'),
    ])
        .then(axios.spread((workspacePlaylistsResponse, workspaceUserPlaylistsResponse) => {
            const workspacePlaylists = workspacePlaylistsResponse.data.data;
            const workspaceUserPlaylists = workspaceUserPlaylistsResponse.data.data;

            // Combine both results
            playlistCards.value = [...workspacePlaylists, ...workspaceUserPlaylists];
            totalItems.value = playlistCards.value.length;
        }))
        .catch((error) => {
            console.error('Failed to fetch workspace playlists:', error);
        })
        .finally(() => {
            isPlaylistLoading.value = false;
        });
}

const showRemoveFromPlaylistConfirmation = (value,playlistId) => {
    removeFromPlaylistStore.playlistIdToRemoveFrom = playlistId;
    removeFromPlaylistStore.isShowRemoveFromPlaylistConfirmationModal = value;
}

const showNotificationAndRefreshPlaylist = (id) => {
    //display notification
    notificationTopBarStore.notificationType = 'deletePlaylist';
    notificationTopBarStore.notificationMessage = `Playlist Telah Dipadam.`;
    notificationTopBarStore.idUsedInAction = id;
    notificationTopBarStore.setIsShowNotificationTopBar(true);
    fetchWorkspacePlaylists();
}

const restorePlaylist = async (id) => {
    try {
        await axios.post(`api/v1/user-playlists/${id}/restore`);
        
    } catch (error) {
        console.error('Error restoring playlist:', error);
    } finally {
        fetchWorkspacePlaylists();
        notificationTopBarStore.resetNotificationTopBar();
    }
}

const goToDocumentPage = () => {
  router.push(`/document`);
}

const paginatedItems = computed(() => {
    const start = (currentPage.value - 1) * itemsPerPage.value;
    const end = start + itemsPerPage.value;

    if(activeTab.value == 'playlist'){
        return playlistCards.value.slice(start, end);
    }
    else{
        return documentsCard.value.slice(start, end);
    }
});

onMounted(() => {
    addToPlaylistStore.closeAddOrCreatePlaylistTopBar();
    fetchWorkspacePlaylists();
    activePageStore.setSelectedActivePage(11)
})
</script>

<template class="overflow-x-hidden">
    <div>
        <NotificationTopBar v-show="notificationTopBarStore.isShowNotificationTopBar" @cancel-delete-playlist-action="restorePlaylist" />
        <AddOrCreatePlaylistTopBar v-if="addToPlaylistStore.showAddOrCreatePlaylistTopBar" />
        <AddToPlaylistDropdown v-if="addToPlaylistStore.showAddToPlaylistDropdown" />
        <CreateEditPlaylistModal v-if="addToPlaylistStore.showCreateOrEditPlaylistModal" :isAtPlaylistPages="false"/>
        <page-navigator :breadcrumbs="breadcrumbs" />
        <div :class="{'text-black': modeStore.selectedMode === 'light', ' text-white': modeStore.selectedMode === 'dark'}" class="flex justify-between justify-items-center my-auto py-4 px-[30px]">
            <h2 :class="{'mobile-title': isMobile, 'page-top-title': !isMobile}">{{ $t('title.workspace')}}</h2>
        </div>
        
        <section 
            class="flex flex-col gap-8 rounded-xl p-[30px] bg-transparent"
            :class="{'bg-transparent': modeStore.selectedMode === 'light', 'bg-custom-blue-gray text-white': modeStore.selectedMode === 'dark'}"
        >
            <!-- <div v-show="isPlaylistLoading" class="flex items-center justify-center w-full gap-2">
                <div>{{ $t('general.loading')}}</div>
                <span class="loading loading-bars loading-sm"></span>
            </div> -->

            <div class="flex gap-2">
                <TabList
                    v-model:activeTab="activeTab"
                    :tabs="tabList"
                />
            </div>
            <div v-show="activeTab === 'playlist' && isPlaylistLoading">
                <div 
                    class="flex items-center mb-4 gap-2"
                    :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
                >
                    <router-link :to="{name: 'Playlist Page'}" class="title-action flex">
                        <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">Playlist</h2>
                        <i class="fa-solid fa-chevron-right fa-lg px-2 my-auto"></i>
                    </router-link>
                </div>

                <div class="flex flex-row overflow-x-auto md:grid md:grid-cols-3 justify-items-center gap-4">
                    <PlaylistCardSkeletonLoader :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                    <PlaylistCardSkeletonLoader :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                    <PlaylistCardSkeletonLoader :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                </div>
            </div>

            <div v-show="activeTab === 'playlist' && !isPlaylistLoading && playlistCards && playlistCards.length > 0">
                <div 
                    class="flex items-center mb-4 gap-2"
                    :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
                >
                    <router-link :to="{name: 'Playlist Page'}" class="title-action flex">
                        <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">Playlist</h2>
                        <i class="fa-solid fa-chevron-right fa-lg px-2 my-auto"></i>
                    </router-link>
                </div>

                <div class="flex flex-row overflow-x-auto md:grid md:grid-cols-3 justify-items-center gap-4">
                    <PlaylistCardForTeacher
                        class="min-w-full md:min-w-0 lg:w-full"
                        v-for="playlist in paginatedItems"
                        :playlistId="playlist.id"
                        :key="playlist.id"
                        :playlistThumbnail="playlist.playlist_thumbnail"
                        :subject="playlist.title"
                        :teacher="playlist.created_by?.name"
                        :grade="playlist.access_groups"
                        :documentCount="playlist.document_count"
                        :interactionCount="playlist.interactive_count"
                        :videoCount="playlist.video_count"
                        :isAddedToWorkspace="playlist.has_added_to_workspace"
                        :isTeacherPlaylist=playlist.teacher_playlist
                        @refresh-teacher-playlist-api="fetchWorkspacePlaylists"
                        @update-delete="showRemoveFromPlaylistConfirmation"
                    />

                    <RemoveFromPlaylistConfirmation @success-delete-playlist="showNotificationAndRefreshPlaylist" @refresh-teacher-playlist-api="fetchWorkspacePlaylists" v-if="removeFromPlaylistStore.isShowRemoveFromPlaylistConfirmationModal" />
                </div>
            </div>

            <!-- <div v-show="isAssetBroadcastsLoading" class="flex items-center justify-center w-full gap-2">
                <div>{{ $t('general.loading')}}</div>
                <span class="loading loading-bars loading-sm"></span>
            </div> -->

            <div v-show="isAssetBroadcastsLoading && activeTab === 'documents'" class="min-h-screen">
                <div 
                    class="flex items-center mb-4 gap-2"
                    :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
                >
                    <router-link :to="{name : 'Document Page'}" class="title-action flex">
                        <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('title.DELIMaCollection')}}</h2>
                        <i class="fa-solid fa-chevron-right fa-lg px-2 my-auto"></i>
                    </router-link>
                </div>

                <div 
                    class="grid grid-cols-2 gap-y-12 md:grid-cols-4 md:gap-4 justify-items-center"
                    :class="{'document-mobile-container' : isMobile}"
                >
                    <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                    <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                    <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                    <DocumentSkeletonCard :dark="modeStore.selectedMode === 'dark'" class="min-w-full md:min-w-0 lg:w-full"/>
                </div>
            </div>

            <div v-show="!isAssetBroadcastsLoading && activeTab === 'documents' && documentsCard && documentsCard.length > 0">
                <div 
                    class="flex items-center mb-4 gap-2"
                    :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}"
                >
                    <router-link :to="{name : 'Document Page'}" class="title-action flex">
                        <h2 :class="{'mobile-title': isMobile, 'title': !isMobile}">{{ $t('title.DELIMaCollection')}}</h2>
                        <i class="fa-solid fa-chevron-right fa-lg px-2 my-auto"></i>
                    </router-link>
                </div>

                <div 
                    class="grid grid-cols-2 gap-y-12 md:grid-cols-4 md:gap-4 justify-items-center"
                    :class="{'document-mobile-container' : isMobile}"
                >
                    <DocumentsCard
                        class="md:min-w-0 lg:w-full"
                        v-for="document in paginatedItems"
                        :key="document.id"
                        :id="document.id"
                        :image="document.thumbnails && document.thumbnails.length > 0 ? document.thumbnails[0].url : ''"
                        :topic="document.name"
                        :groups="document.groups ? document.groups : []"
                        :subjects="document.subjects ? document.subjects : []"
                        :postedTime="document.postedTime"
                        :tags="document.tags"
                        :isAddedToWorkspace="document.has_added_to_workspace"
                        :contentType="document.content_type"
                        @refresh-asset-api="fetchWorkspaceAssetBroadcasts"
                    />
                </div>
            </div>

            <div v-if="((playlistCards.length === 0 && activeTab !== 'documents') || (documentsCard.length === 0 && activeTab === 'documents')) && (!isPlaylistLoading || !isAssetBroadcastsLoading)" 
                class="h-screen my-auto">
                <!-- <h2 class="text-2xl text-center font-bold">{{ $t('general.noData')}}</h2> -->
                 <div :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}" class="flex flex-col items-center justify-center my-auto py-10">
                    <div>
                        <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect y="0.5" width="80" height="80" rx="20" fill="#E5E7EB"/>
                        <path d="M35 42.6663H45M40 37.6663V47.6663M25 49.333V32.6663C25 30.8254 26.4924 29.333 28.3333 29.333H38.3333L41.6667 32.6663H51.6667C53.5076 32.6663 55 34.1587 55 35.9997V49.333C55 51.174 53.5076 52.6663 51.6667 52.6663H28.3333C26.4924 52.6663 25 51.174 25 49.333Z" stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class="text-center py-2 text-base">{{ $t('workspacePage.noFileInWorkspace')}}</div>
                    <div class="text-center w-[60%] text-sm text-gray-400">{{ $t('workspacePage.description1')}}<br>
                        {{ $t('workspacePage.description2')}}<span class="font-semibold" :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}">Chatbox</span> {{ $t('workspacePage.description3')}}.
                    </div>
                    <div class="py-3">
                        <div @click="goToDocumentPage" :class="{ 'bg-theme-green': themeStore.selectedTheme === 'green', 'bg-theme-orange': themeStore.selectedTheme === 'orange', 'bg-theme-blue': themeStore.selectedTheme === 'blue', 'bg-theme-purple': themeStore.selectedTheme === 'purple', 'bg-theme-darkpink': themeStore.selectedTheme === 'darkpink'}" class="w-[250px] py-2 px-4 rounded-md text-white cursor-pointer text-center"><span class="text-base lg:text-xl">+</span> {{ $t('workspacePage.addMyFavourites')}}</div>
                    </div>
                    
                 </div>
            </div>

            <!-- Pagination -->
            <div v-show="!isPlaylistLoading || !isAssetBroadcastsLoading" class="hidden lg:block">
                <CustomPagination
                    :showingFrom="showingFrom"
                    :showingTo="showingTo"
                    :totalItems="totalItems"
                    :totalPages="totalPages"
                    :currentPage="currentPage"
                    :itemsPerPage="itemsPerPage"
                    @change-page="changePage"
                />
            </div>
            
        </section>
    </div>
</template>