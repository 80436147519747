<script>
export default {
  data() {
    return {
      selectedOption: '',
      isButtonDisabled: true,
    };
  },
  computed: {
    buttonClass() {
      return this.selectedOption ? 'button-active' : 'button-disabled';
    },
  },
  methods: {
    updateButton() {
      this.isButtonDisabled = this.selectedOption === '';
    },
    handleButtonClick() {
      if (this.selectedOption) {
        window.open(this.selectedOption, '_blank');
      }
    },
  },
};
</script>

<template>
  <div class="landing">
    <div class="center-content">
      <!-- SECTION - HERO -->
      <main class="sectionhero">
        <nav class="header">
          <div class="frame-3">
            <router-link :to="{'name' : 'Welcome Page'}" class="header-logo"><img src="../assets/icons/WelcomePage/delimaflix-logo-color1.svg" class="header-logo"/></router-link>
          </div>
          <div class="login-container">
            <div class="login-section">
              <select id="dropdown-container" class="btn-login" v-model="selectedOption" @change="updateButton">
                <option value="" selected disabled>Pilih Kategori</option>
                <option value="https://delimaflix.moe.gov.my/dashboard">Murid</option>
                <!--<option value="https://app-delima.moe.gov.my/mam/login">BSTP</option>-->
                <option value="https://app-delima.moe.gov.my/mps/login">Guru/KPM/JPN/PPD</option>
              </select>
              <div class="icon-container">
                <img src="../assets/icons/WelcomePage/icon-arrow-down.svg" class=""/>
              </div>
            </div>
            <div class="login-section">
              <button
                id="loginButton"
                :disabled="isButtonDisabled"
                :class="buttonClass"
                @click="handleButtonClick"
              >
                <div class="text-wrapper-3 desktop-only">Log Masuk</div>
                <img src="../assets/icons/WelcomePage/icon-login.svg" class="login-icon mobile-only"/>
              </button>
            </div>
          </div>
        </nav>

        <div class="frame">
          <div class="frame-2">
            <h1 class="delimaflix"><span class="text-wrapper">DELIMaFLiX</span></h1>
            <h2 class="ajar-belajar-di-mana">Belajar lebih seronok, di mana-mana sahaja!</h2>
            <p class="hero-text">
              DELIMaFLiX ialah hab repositori pintar yang menghimpun pelbagai bahan pembelajaran digital seperti video DidikTV, video 
              interaktif, permainan digital, Podcast, komik digital, buku teks dan banyak lagi. Kesemuanya berada dalam satu platform yang 
              selamat, mudah dan menyeronokkan. Kini anda boleh strim, main dan ulangkaji di mana-mana sahaja pada bila-bila masa.
            </p>
          </div>
          <div>
            <a class="btn-blue-l" target="_blank" href="https://delimaflix.moe.gov.my/dashboard">
              <div class="div-2"><div class="text-wrapper-2">Mula Belajar</div></div>
            </a>
          </div>
          <div class="frame">
            <img class="desktop-pelajar" src="../assets/Images/Views/WelcomePage/Hero-img.png" />
          </div>
        </div>
      </main>
      <!-- SECTION - HERO - END -->


      <!-- SECTION 1 - FAEDAH MENGGUNAKAN EDUVAULT -->
      <div class="section1">
        <div class="faedah-menggunakan">
          <h2><span class="text-wrapper-7">Faedah DELIMaFLiX</span> </h2>
        </div>
        <div class="frame-4">
          <div class="frame-5">
            <img class="layer" src="../assets/icons/WelcomePage/faedah-1.svg" />
            <div class="group">
              <div class="subtitle">
                <p class="text-wrapper-9">
                  Akses buku teks digital, video pembelajaran, Podcast KPM, bahan interaktif dan banyak lagi tanpa perlu menukar platform.                </p>
              </div>
              <div class="frame-6">
                <div class="number">
                  <div class="overlap-group"><div class="text-wrapper-10">1</div></div>
                </div>
                <div class="text-wrapper-11">Semua dalam satu platform</div>
              </div>
            </div>
          </div>
          <div class="frame-5">
            <img class="img" src="../assets/icons/WelcomePage/faedah-2.svg" />
            <div class="group-2">
              <div class="subtitle">
                <p class="text-wrapper-9">
                  Gunakan DELIMa ID anda untuk mula belajar serta-merta. Tiada muat turun, tiada gangguan.
                </p>
              </div>
              <div class="frame-7">
                <div class="number">
                  <div class="overlap-group"><div class="text-wrapper-10">2</div></div>
                </div>
                <div class="text-wrapper-11">Guna DELIMa ID</div><!--check here-->
              </div>
            </div>
          </div>
          <div class="frame-5">
            <img class="group-3" src="../assets/icons/WelcomePage/faedah-3.svg" />
            <div class="group-2">
              <div class="subtitle">
                <p class="text-wrapper-9">
                  Guru boleh mencipta, menyusun dan menyebar senarai main khas kepada murid melalui Playlist. Murid boleh akses Playlist 
                  guru dengan mudah untuk belajar.
                </p>
              </div>
              <div class="frame-7">
                <div class="number">
                  <div class="overlap-group"><div class="text-wrapper-12">3</div></div>
                </div>
                <div class="text-wrapper-11">Playlist</div>
              </div>
            </div>
          </div>
          <div class="frame-5">
            <img class="group-4" src="../assets/icons/WelcomePage/faedah-4.svg" />
            <div class="group-5">
              <div class="subtitle">
                <p class="text-wrapper-9">
                  Tiada iklan, hanya kandungan kurikulum, tiada unsur luar. DELIMaFLiX memastikan murid belajar dalam persekitaran terkawal,
                  fokus dan selamat.
                </p>
              </div>
              <div class="frame-9">
                <div class="number">
                  <div class="overlap-group"><div class="text-wrapper-13">4</div></div>
                </div>
                <div class="text-wrapper-11">Selamat & Bebas Gangguan</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- SECTION 1 - END -->

      <!-- SECTION 2 - TENTANG DELIMA FLIX -->
      <div id="section-tentang-EDUVAULT" class="tentang-EDUVAULT">
        <h2><span class="text-wrapper-7">Tentang DELIMaFLiX</span></h2>
      </div>

      <div class="section2">
        <!-- section 2.1 -->
        <div class="group-19">
          <div class="group-20">
            <div class="overlap-7">
              <img class="rectangle-2" src="../assets/Images/Views/WelcomePage/urus-modal-pelajaran-1.png" />
            </div>
          </div>
          <div class="group-20">
            <div class="frame-20">
              <div class="text-wrapper-29">Selamat, berkualiti dan berfokus.</div>
              <p class="text-wrapper-30">
                Sumber pintar kepada pelbagai bahan digital
              </p>
              <div class="frame-21">
                <div class="details">
                  <div class="done">
                    <div class="path-wrapper">
                      <img src="../assets/icons/WelcomePage/tick-icon.svg" />
                    </div>
                  </div>
                  <p class="text-wrapper-31">
                    Repositori pintar untuk guru dan murid mengakses video, Podcast, media interaktif dan dokumen tanpa perlu muat turun
                    bahan atau pemasangan perisian tambahan.
                  </p>                
                </div>
                <div class="details">
                  <div class="done">
                    <div class="path-wrapper">
                      <img src="../assets/icons/WelcomePage/tick-icon.svg" />
                    </div>
                  </div>
                  <p class="text-wrapper-31">
                    Kesemua bahan pembelajaran digital kini boleh diakses melalui satu platform tanpa gangguan, tanpa kerumitan.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- section 2.2 -->
        <div class="group-34">
          <div class="frame-25">
            <div class="frame-20">
              <div class="text-wrapper-29">Apakah keistimewaan DELIMaFLiX?</div>
              <!--<p class="text-wrapper-30">Kandungan Rasmi & Berkualiti</p>-->
              <div class="frame-21">
                <p class="text-wrapper-30">Kandungan Rasmi & Berkualiti</p>
                <div class="details">
                  <div class="done">
                    <div class="overlap-group-9">
                      <img src="../assets/icons/WelcomePage/tick-icon.svg" />
                    </div>
                  </div>
                  <p class="text-wrapper-31">Menyediakan akses kepada bahan pembelajaran digital yang disahkan oleh KPM seperti video, media interaktif, podcast dan dokumen sokongan.</p>
                </div>
                <p class="text-wrapper-30">Kawalan & Susun Atur oleh Guru</p>
                <div class="details">
                  <div class="done">
                    <div class="overlap-group-9">
                      <img src="../assets/icons/WelcomePage/tick-icon.svg" />
                    </div>
                  </div>
                  <p class="text-wrapper-31">Guru boleh membina dan mengatur senarai main kandungan mengikut tahap, kelas atau topik pembelajaran yang sesuai.</p>
                </div>
                <p class="text-wrapper-30">Mudah Dicapai & Teratur</p>
                <div class="details">
                  <div class="done">
                    <div class="overlap-group-9">
                      <img src="../assets/icons/WelcomePage/tick-icon.svg" />
                    </div>
                  </div>
                  <p class="text-wrapper-31">
                    Semua bahan disusun mengikut kategori dan format untuk memudahkan pencarian dan penggunaan oleh murid dan guru
                  </p>
                </div>
                <p class="text-wrapper-30">Selamat & Terkawal</p>
                <div class="details">
                  <div class="done">
                    <div class="overlap-group-9">
                      <img src="../assets/icons/WelcomePage/tick-icon.svg" />
                    </div>
                  </div>
                  <p class="text-wrapper-31">
                    Kandungan yang dipaparkan merupakan koleksi terpilih bagi memastikan pengalaman pembelajaran digital yang selamat dan berfokus kepada kurikulum.                  </p>
                </div>
              </div>
            </div>

          </div>
          <div class="group-35">
            <div class="overlap-13">
              <img class="rectangle-21" src="../assets/Images/Views/WelcomePage/mudah-digunakan-2.png" /> 
            </div>
          </div>
        </div>

        <!-- section 2.3 -->
        <div class="group-22">
          <div class="group-20">
            <div class="overlap-9">
              <img class="rectangle-3" src="../assets/Images/Views/WelcomePage/perkayakan-ilmu-pengetahuan-3.png" />
            </div>
          </div>
          <div class="frame-22">
            <div class="frame-20">
              <div class="text-wrapper-29">Tingkatkan Pengalaman Pembelajaran</div>
              <div class="text-wrapper-30">Kandungan Berstruktur & Mudah Dicari</div>
              <div class="frame-21">
                <div class="details">
                  <div class="done">
                    <div class="img-wrapper">
                      <img class="path" src="../assets/icons/WelcomePage/tick-icon.svg" />
                    </div>
                  </div>
                  <p class="text-wrapper-31">
                    Kandungan dipaparkan mengikut kategori, tahap atau subjek untuk memudahkan murid membuat carian bahan pembelajaran serta menyokong pembelajaran yang diperibadikan.                  </p>
                </div>
                <div class="text-wrapper-30">Senarai Main Peribadi</div>
                <div class="details">
                  <div class="done">
                    <div class="img-wrapper">
                      <img class="path" src="../assets/icons/WelcomePage/tick-icon.svg" />
                    </div>
                  </div>
                  <p class="text-wrapper-31">
                    Murid boleh simpan, susun dan ulang main bahan kegemaran mereka dalam Playlist.
                  </p>
                </div>
                <div class="text-wrapper-30">Kebolehcapaian & Bahasa</div>
                <div class="details">
                  <div class="done">
                    <div class="img-wrapper">
                      <img class="path" src="../assets/icons/WelcomePage/tick-icon.svg" />
                    </div>
                  </div>
                  <p class="text-wrapper-31">
                    Repositori pintar yang menyokong pelbagai bahasa dan ciri mesra pengguna untuk memastikan semua murid mendapat pengalaman pembelajaran yang sama rata.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- SECTION 2 - END -->

      <!-- SECTION 3 - GUNAKAN DELIMA FLIX -->
      <div class="terokai">
        <div class="terokai-left">
          <img class="terokai-img" src="../assets/Images/Views/WelcomePage/terokai-img.png" />
        </div>
        <div class="terokai-right">
          <div class="text-wrapper-29">Inisiatif Kementerian Pendidikan Malaysia</div><!--review-->
          <p class="text-wrapper-terokai">
            <br>DELIMaFLiX ialah sebahagian daripada inisiatif Dasar Pendidikan Digital untuk memperkasakan pendidikan masa hadapan - selamat, menyeronokkan dan mudah diakses oleh semua murid di seluruh negara.</p>
          <br>
        </div>
      </div>
      <!-- SECTION 3 - END -->

      <!-- SECTION FOOTER -->
      <div class="overlap-2">
        <div class="overlap-3">
          <div class="frame-10">
            <div class="frame-11">
              <div class="frame-3">
                <img src="../assets/icons/WelcomePage/delimaflix-logo-color.svg" class="header-logo"/>
              </div>
              <div class="menggabungkan-tempat">Menggabungkan tempat <br />pengajaran dan pembelajaran.</div>
              <div class="footer-list">
                <div>
                  <ul>
                    <li class="text-wrapper-17"><router-link :to="{'name' : 'Privacy Page'}" class="text-gray-500">Privasi & Polisi</router-link></li>
                    <li class="text-wrapper-17"><router-link :to="{'name' : 'Term Page'}" class="text-gray-500">Terma & Syarat</router-link></li>
                  </ul>
                </div>  
              </div>
            </div>

          </div>

          <div class="frame-10">
            <img class="phone-cikgu" src="../assets/Images/Views/WelcomePage/desktop-mobile-footer.png" />
          </div>
        </div>
      </div>
      <!-- SECTION FOOTER - END -->
    </div>
  </div>
</template>