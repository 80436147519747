<script setup>
import router from "../router/index.js";

const goLoginPage = () => {
    router.push({
        name: 'Login Page',
      });
}
</script>

<template>
    <div class="relative h-screen bg-[#121212]">
        <div class="fi-simple-layout flex min-h-screen flex-col items-center">
            <div class="fi-simple-main-ctn flex w-full flex-grow items-center justify-center">
                <main class="fi-simple-main my-16 flex flex-col justify-between w-full px-6 py-12 shadow-sm ring-1 bg-[#121212] ring-white/10 sm:max-w-lg sm:rounded-xl sm:px-12">
                    <div class="page-logo mb-4 flex justify-center">
                        <img class="h-10" src="../assets/DelimeFlixWithLogo.png">
                    </div>
                    <div class="page-content">
                        <div class="fi-simple-page flex flex-col justify-between items-center">
                            <h2 class="mb-4 text-white">You do not have the authorization to view this page.</h2>
                            <a class="underline text-white" @click.prevent="goLoginPage()">Back to Login</a>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>