<script setup>
import {ref, nextTick, onMounted, watch} from 'vue'
import { useModeStore } from '../store/mode.js';
import {usePlaybackStore} from "@/store/playbackStore";
import {useAllSearchResultStore} from "@/store/allSearchResult";
import {useRoute} from "vue-router";
import axios from "axios";
import videojs from "video.js";
import PageNavigator from "@/components/PageNavigator.vue";
import {useActivePageStore} from "@/store/activePageTab";
import { useTranslation } from "i18next-vue";

// const router = useRouter();
const { t } = useTranslation();
const route = useRoute();
const modeStore = useModeStore();
const isShow = ref(false);
const videoPlayer = ref(null);
const activePageStore = useActivePageStore();
const playbackStore = usePlaybackStore();
const allSearchResultStore = useAllSearchResultStore();
const pagePlayerElement = ref(null);
const podcastId = ref(route.params.id);
// const assets = ref([]);
const podcast = ref(null);
const isLoading = ref(true);
// const isListLoading = ref(true);
// const openContainer = ref(true);
const breadcrumbs = [
  { text: 'Laman Utama', to: { name: 'Dashboard Page' } },
  { text: t('title.podcast') },
];

// const podcastDetailImage = computed(() => {
//     return require('../assets/Images/Views/DashboardPage/dashboard_banner_new_img_cropped_compressed_2.png')
// });

const fetchPodcastData = async () => {
  try {
    const response = await axios.get(`/api/v1/asset-broadcasts/${podcastId.value}`);
    podcast.value = response.data;

    // update once when user clicked into here.
    //updateAssetBroadcastProgress(podcast.value.id);
    nextTick(() => {
      if (podcast.value.content_type === 1) { // video type
        videoPlayer.value = videojs(document.querySelector('.video-js'), {
          controls: true, // hide the default controls since we using PlayerControls component
        });

        nextTick(() => {
          // check if route has query playFrom then set the current time of video to it
          if (route.query.playFrom) {
            videoPlayer.value.currentTime(route.query.playFrom); // in seconds
            try {
              // muted first
              videoPlayer.value.muted(true);
              // auto play if user interacted with document first
              videoPlayer.value.play();

              // update the playback store isPlaying
              playbackStore.updatePlayingState(true);
            } catch (error) {
              console.error('Failed to play video:', error);
            }
          }

          videoPlayer.value.on('play', () => {
            if (!playbackStore.isPlaying) {
              playbackStore.updatePlayingState(true);
            }
          });
          videoPlayer.value.on('pause', () => {
            if (playbackStore.isPlaying) {
              playbackStore.updatePlayingState(false);
              //updateAssetBroadcastProgress(podcast.value.id)
            }
          });

          // on current time changes
          videoPlayer.value.on('timeupdate', () => {
            playbackStore.updateCurrentTime(videoPlayer.value.currentTime());

            if (videoPlayer.value.currentTime() % 10 === 0) {
              //updateAssetBroadcastProgress(podcast.value.id)
            }
          });

          // duration of video
          videoPlayer.value.on('durationchange', () => {
            playbackStore.updateDuration(videoPlayer.value.duration());
          });

          //updateCurrentlyPlayingDocument(podcast.value);
        });
      }
    });
  } catch (error) {
    console.error('Failed to fetch asset data:', error);
  } finally {
    isLoading.value = false;
  }
}

onMounted( () => {
  activePageStore.setSelectedActivePage(9);
  allSearchResultStore.setShowSearchResult(false);
  fetchPodcastData()
})

watch(() => route.params.id, (newId) => {
  if (podcast.value !== newId) {
    podcast.value = newId; // Update local reactive value
    isLoading.value = true;
    fetchPodcastData(); // Fetch new data
  }
});

</script>

<template>
    <div class="min-h-screen">
      <page-navigator :breadcrumbs="breadcrumbs" />
        <div :class="{'text-black': modeStore.selectedMode === 'light', 'text-white': modeStore.selectedMode === 'dark'}" class="flex justify-between justify-items-center my-auto p-4">
            <h2 :class="{'mobile-title': isMobile, 'page-top-title': !isMobile}">{{ podcast?.title }}</h2>
        </div>

        <section 
            class="flex flex-col gap-8 rounded-xl p-4 bg-transparent"
            :class="{'text-black': modeStore.selectedMode === 'light', ' text-white': modeStore.selectedMode === 'dark'}"
        >
          <div v-if="isLoading" class="flex justify-center items-center h-64">
            <div class="loader"></div>
          </div>
            
            <div v-if="podcast">
                <div class="flex flex-1">
                    <div class="flex-grow">
                        <div class="video-container">
                            <video ref="pagePlayerElement" class="video-js" preload="auto">
                                <source :src="podcast.file_preview_path" type="video/mp4" />
                                <p class="vjs-no-js">
                                To view this video please enable JavaScript, and consider upgrading to a
                                web browser that
                                <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                                </p>
                            </video>
                        </div>
                    </div>

                    <div v-if="!isShow" class="pl-10">
                        <svg width="59" height="48" viewBox="0 0 59 48" fill="none" xmlns="http://www.w3.org/2000/svg" @click="isShow = true">
                        <g filter="url(#filter0_d_13449_62103)">
                        <rect x="2" y="1" width="55" height="44" rx="10" fill="#EA580C"/>
                        <path d="M39.6562 17.5111C39.6562 17.3027 39.5729 17.1048 39.4271 16.959L35.5521 13.084C35.25 12.7819 34.75 12.7819 34.4479 13.084C34.1458 13.3861 34.1458 13.8861 34.4479 14.1882L37 16.7402H20.125C19.6979 16.7402 19.3438 17.0944 19.3438 17.5215C19.3438 17.9486 19.6979 18.2923 20.125 18.2923H36.9896L34.4479 20.834C34.1458 21.1361 34.1458 21.6361 34.4479 21.9382C34.6042 22.0944 34.8021 22.1673 35 22.1673C35.1979 22.1673 35.3958 22.0944 35.5521 21.9382L39.4167 18.0736C39.4896 18.0007 39.5521 17.9069 39.5938 17.8027C39.5938 17.7923 39.5938 17.7819 39.6042 17.7715C39.6354 17.6882 39.6562 17.6048 39.6562 17.5111Z" fill="white"/>
                        <path d="M38.875 27.7083H22.0104L24.5521 25.1667C24.8542 24.8646 24.8542 24.3646 24.5521 24.0625C24.25 23.7604 23.75 23.7604 23.4479 24.0625L19.5833 27.9271C19.5104 28 19.4479 28.0937 19.4062 28.1979C19.4062 28.2083 19.4062 28.2188 19.3958 28.2292C19.3646 28.3125 19.3438 28.4063 19.3438 28.5C19.3438 28.7083 19.4271 28.9062 19.5729 29.0521L23.4479 32.9271C23.6042 33.0833 23.8021 33.1562 24 33.1562C24.1979 33.1562 24.3958 33.0833 24.5521 32.9271C24.8542 32.625 24.8542 32.125 24.5521 31.8229L22 29.2708H38.875C39.3021 29.2708 39.6562 28.9167 39.6562 28.4896C39.6562 28.0625 39.3021 27.7083 38.875 27.7083Z" fill="white"/>
                        </g>
                        <defs>
                        <filter id="filter0_d_13449_62103" x="0" y="0" width="59" height="48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="1"/>
                        <feGaussianBlur stdDeviation="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13449_62103"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13449_62103" result="shape"/>
                        </filter>
                        </defs>
                        </svg>
                    </div>

                    <div v-else class="pl-10 w-[480px] h-[500px]">
                        <div class="flex items-center justify-between bg-black bg-opacity-10 px-4 px-2 rounded-t-xl">
                        <span class="font-bold text-xl">Fail Seterusnya</span>
                        <svg width="50" height="49" viewBox="0 0 59 48" fill="none" xmlns="http://www.w3.org/2000/svg" @click="isShow = false">
                            <g filter="url(#filter0_d_13440_20782)">
                            <rect x="2" y="1" width="55" height="44" rx="10" fill="#EA580C"/>
                            <path d="M38.8749 32.3749L20.125 13.625M38.8751 13.625L20.125 32.3751" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                            <filter id="filter0_d_13440_20782" x="0" y="0" width="59" height="48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="1"/>
                            <feGaussianBlur stdDeviation="1"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13440_20782"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13440_20782" result="shape"/>
                            </filter>
                            </defs>
                        </svg>
                        </div>
                        <!-- <div v-if="isListLoading" class="flex justify-center items-center h-[450px] bg-black bg-opacity-10 rounded-b-xl">
                            <div class="loader"></div>
                        </div>
                        <div v-else class="scrollable-container bg-black bg-opacity-10">
                            <div v-for="item in assets" :key="item.id" class="flex p-4 hover:bg-white hover:bg-opacity-5">
                                <img @click.prevent="goToDocument(item.id)" :src="item.thumbnails[0].url" alt="playlist image" class="h-20 w-20 rounded-md">
                                <div class="flex flex-col w-56 px-6">
                                    <span @click.prevent="goToDocument(item.id)">{{ item.title }}</span>
                                    <div class="flex flex-wrap">
                                        <span class="text-xs text-gray-300">{{ item.tags.map(tag => tag.name).join(' | ') }}</span>
                                    </div>
                                </div>
                                <div             
                                :class="{
                                    'bg-[#FEF3C7] text-[#92400E]': item.content_type === 1,
                                    'bg-green-100 text-green-800': item.content_type === 2,
                                    'bg-gray-100 text-gray-800': item.content_type === 3
                                }" class="badge badge-lg border-0 rounded-xl">
                                    {{ getContentTypeLabel(item.content_type) }}
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                
                <div class="podcast-details my-4 flex flex-row justify-between content-center items-center">
                    <div class="flex flex-row gap-3 col-span-2 w-1/5 justify-start max-w-[400px]">
                        <img :src="podcast.thumbnails[0].url" alt="Podcast Thumbnail" class="w-24 h-24 object-cover mb-4" />

                        <div class="flex flex-col gap-2 overflow-hidden">
                            <h2 class="font-bold truncate overflow-clip">{{ podcast.title }}</h2>
                        </div>
                    </div>
<!--                    <div class="w-3/5 justify-center">-->
<!--                        <player-controls-->
<!--                            @rewind="rewind"-->
<!--                            @skip-prev="skipPrev"-->
<!--                            @toggle-play="togglePlay"-->
<!--                            @skip-next="skipNext"-->
<!--                            @forward="forward"-->
<!--                            :current-playing="isPlaying"-->
<!--                        ></player-controls>-->
<!--                    </div>-->
                    <div class="w-1/5 justify-end max-w-[200px]" >
                       
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<style scoped>
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.video-container .video-js {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.scrollable-container {
  height: 450px; /* Adjust based on your design */
  overflow-y: auto;
  border-bottom-right-radius: 0.75rem; 
  border-bottom-left-radius: 0.75rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>