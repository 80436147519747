import { defineStore } from 'pinia'

export const useDocumentPageFilterStore = defineStore('documentPageFilter', {
    state: () => {
      return {
        isFilterDisplay: false,
        filterByDocumentType: 'all',
        filterByUploadedBy: 'admin',
        filterByCourseLevel: 'all',
        filterBySubject: 'all',
        filterByModule: 'all',
        filterByModulTunjang: 'all',
        filterByDate: null,
      }
    },
    actions: {
        setIsFilterDisplay(value) {
            this.isFilterDisplay = value
        },
        setFilterByDocumentType(value) {
            this.filterByDocumentType = value
        },
        setFilterByUploadedBy(value) {
            this.filterByUploadedBy = value
        },
        setFilterByCourseLevel(value) {
            this.filterByCourseLevel = value
        },
        setFilterBySubject(value) {
            this.filterBySubject = value
        },
        setFilterByModule(value) {
            this.filterByModule = value
        },
        setFilterByModulTunjang(value) {
            this.filterByModulTunjang = value
        },
        resetFilter() {
            this.isFilterDisplay = false
            this.filterByDocumentType = 'all'
            this.filterByUploadedBy = 'admin'
            this.filterByCourseLevel = 'all'
            this.filterBySubject = 'all'
            this.filterByModule = 'all'
            this.filterByModulTunjang = 'all'
            // this.filterByDate = null
        },
        toggleFilterDisplay() {
            this.isFilterDisplay = !this.isFilterDisplay
        }
    },
    persist: true,
  })